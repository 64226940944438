/* http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: 16px/1 sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 64rem;
  margin: 0 auto;
}

@media print, screen and (min-width: 40em) {
  .grid-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.fluid {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
  margin: 0 auto;
}

@media print, screen and (min-width: 40em) {
  .grid-container.fluid {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin: 0 auto;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}

.cell.auto {
  flex: 1 1 0px;
}

.cell.shrink {
  flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}

.grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}

.grid-x > .small-1 {
  width: 8.33333%;
}

.grid-x > .small-2 {
  width: 16.66667%;
}

.grid-x > .small-3 {
  width: 25%;
}

.grid-x > .small-4 {
  width: 33.33333%;
}

.grid-x > .small-5 {
  width: 41.66667%;
}

.grid-x > .small-6 {
  width: 50%;
}

.grid-x > .small-7 {
  width: 58.33333%;
}

.grid-x > .small-8 {
  width: 66.66667%;
}

.grid-x > .small-9 {
  width: 75%;
}

.grid-x > .small-10 {
  width: 83.33333%;
}

.grid-x > .small-11 {
  width: 91.66667%;
}

.grid-x > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .medium-1 {
    width: 8.33333%;
  }
  .grid-x > .medium-2 {
    width: 16.66667%;
  }
  .grid-x > .medium-3 {
    width: 25%;
  }
  .grid-x > .medium-4 {
    width: 33.33333%;
  }
  .grid-x > .medium-5 {
    width: 41.66667%;
  }
  .grid-x > .medium-6 {
    width: 50%;
  }
  .grid-x > .medium-7 {
    width: 58.33333%;
  }
  .grid-x > .medium-8 {
    width: 66.66667%;
  }
  .grid-x > .medium-9 {
    width: 75%;
  }
  .grid-x > .medium-10 {
    width: 83.33333%;
  }
  .grid-x > .medium-11 {
    width: 91.66667%;
  }
  .grid-x > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .large-1 {
    width: 8.33333%;
  }
  .grid-x > .large-2 {
    width: 16.66667%;
  }
  .grid-x > .large-3 {
    width: 25%;
  }
  .grid-x > .large-4 {
    width: 33.33333%;
  }
  .grid-x > .large-5 {
    width: 41.66667%;
  }
  .grid-x > .large-6 {
    width: 50%;
  }
  .grid-x > .large-7 {
    width: 58.33333%;
  }
  .grid-x > .large-8 {
    width: 66.66667%;
  }
  .grid-x > .large-9 {
    width: 75%;
  }
  .grid-x > .large-10 {
    width: 83.33333%;
  }
  .grid-x > .large-11 {
    width: 91.66667%;
  }
  .grid-x > .large-12 {
    width: 100%;
  }
}

.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
}

.grid-margin-x > .cell {
  width: calc(100% - 0.625rem);
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}

.grid-margin-x > .auto {
  width: auto;
}

.grid-margin-x > .shrink {
  width: auto;
}

.grid-margin-x > .small-1 {
  width: calc(8.33333% - 0.625rem);
}

.grid-margin-x > .small-2 {
  width: calc(16.66667% - 0.625rem);
}

.grid-margin-x > .small-3 {
  width: calc(25% - 0.625rem);
}

.grid-margin-x > .small-4 {
  width: calc(33.33333% - 0.625rem);
}

.grid-margin-x > .small-5 {
  width: calc(41.66667% - 0.625rem);
}

.grid-margin-x > .small-6 {
  width: calc(50% - 0.625rem);
}

.grid-margin-x > .small-7 {
  width: calc(58.33333% - 0.625rem);
}

.grid-margin-x > .small-8 {
  width: calc(66.66667% - 0.625rem);
}

.grid-margin-x > .small-9 {
  width: calc(75% - 0.625rem);
}

.grid-margin-x > .small-10 {
  width: calc(83.33333% - 0.625rem);
}

.grid-margin-x > .small-11 {
  width: calc(91.66667% - 0.625rem);
}

.grid-margin-x > .small-12 {
  width: calc(100% - 0.625rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem);
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: calc(8.33333% - 1.25rem);
  }
  .grid-margin-x > .medium-2 {
    width: calc(16.66667% - 1.25rem);
  }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 1.25rem);
  }
  .grid-margin-x > .medium-4 {
    width: calc(33.33333% - 1.25rem);
  }
  .grid-margin-x > .medium-5 {
    width: calc(41.66667% - 1.25rem);
  }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 1.25rem);
  }
  .grid-margin-x > .medium-7 {
    width: calc(58.33333% - 1.25rem);
  }
  .grid-margin-x > .medium-8 {
    width: calc(66.66667% - 1.25rem);
  }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 1.25rem);
  }
  .grid-margin-x > .medium-10 {
    width: calc(83.33333% - 1.25rem);
  }
  .grid-margin-x > .medium-11 {
    width: calc(91.66667% - 1.25rem);
  }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: calc(8.33333% - 1.25rem);
  }
  .grid-margin-x > .large-2 {
    width: calc(16.66667% - 1.25rem);
  }
  .grid-margin-x > .large-3 {
    width: calc(25% - 1.25rem);
  }
  .grid-margin-x > .large-4 {
    width: calc(33.33333% - 1.25rem);
  }
  .grid-margin-x > .large-5 {
    width: calc(41.66667% - 1.25rem);
  }
  .grid-margin-x > .large-6 {
    width: calc(50% - 1.25rem);
  }
  .grid-margin-x > .large-7 {
    width: calc(58.33333% - 1.25rem);
  }
  .grid-margin-x > .large-8 {
    width: calc(66.66667% - 1.25rem);
  }
  .grid-margin-x > .large-9 {
    width: calc(75% - 1.25rem);
  }
  .grid-margin-x > .large-10 {
    width: calc(83.33333% - 1.25rem);
  }
  .grid-margin-x > .large-11 {
    width: calc(91.66667% - 1.25rem);
  }
  .grid-margin-x > .large-12 {
    width: calc(100% - 1.25rem);
  }
}

.grid-padding-x .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x > .cell {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.33333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.66667%;
}

.small-up-7 > .cell {
  width: 14.28571%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%;
  }
  .medium-up-2 > .cell {
    width: 50%;
  }
  .medium-up-3 > .cell {
    width: 33.33333%;
  }
  .medium-up-4 > .cell {
    width: 25%;
  }
  .medium-up-5 > .cell {
    width: 20%;
  }
  .medium-up-6 > .cell {
    width: 16.66667%;
  }
  .medium-up-7 > .cell {
    width: 14.28571%;
  }
  .medium-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }
  .large-up-2 > .cell {
    width: 50%;
  }
  .large-up-3 > .cell {
    width: 33.33333%;
  }
  .large-up-4 > .cell {
    width: 25%;
  }
  .large-up-5 > .cell {
    width: 20%;
  }
  .large-up-6 > .cell {
    width: 16.66667%;
  }
  .large-up-7 > .cell {
    width: 14.28571%;
  }
  .large-up-8 > .cell {
    width: 12.5%;
  }
}

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 0.625rem);
}

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 0.625rem);
}

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 0.625rem);
}

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 0.625rem);
}

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 0.625rem);
}

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 0.625rem);
}

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 0.625rem);
}

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 0.625rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 0.625rem);
  }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 0.625rem);
  }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 0.625rem);
  }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 0.625rem);
  }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 0.625rem);
  }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 0.625rem);
  }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 0.625rem);
  }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 0.625rem);
  }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.25rem);
  }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.25rem);
  }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.25rem);
  }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.25rem);
  }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.25rem);
  }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.25rem);
  }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.25rem);
  }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.25rem);
  }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.25rem);
  }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.25rem);
  }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.25rem);
  }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.25rem);
  }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.25rem);
  }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.25rem);
  }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.25rem);
  }
}

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .cell {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .small-1 {
  width: 8.33333%;
}

.small-margin-collapse > .small-2 {
  width: 16.66667%;
}

.small-margin-collapse > .small-3 {
  width: 25%;
}

.small-margin-collapse > .small-4 {
  width: 33.33333%;
}

.small-margin-collapse > .small-5 {
  width: 41.66667%;
}

.small-margin-collapse > .small-6 {
  width: 50%;
}

.small-margin-collapse > .small-7 {
  width: 58.33333%;
}

.small-margin-collapse > .small-8 {
  width: 66.66667%;
}

.small-margin-collapse > .small-9 {
  width: 75%;
}

.small-margin-collapse > .small-10 {
  width: 83.33333%;
}

.small-margin-collapse > .small-11 {
  width: 91.66667%;
}

.small-margin-collapse > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .small-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.3125rem);
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.3125rem);
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.3125rem);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.3125rem);
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.3125rem);
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.3125rem);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.3125rem);
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.3125rem);
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.3125rem);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.3125rem);
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.3125rem);
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.3125rem);
}

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.625rem);
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.625rem);
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.625rem);
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.625rem);
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.625rem);
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.625rem);
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.625rem);
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.625rem);
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.625rem);
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.625rem);
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.625rem);
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.625rem);
  }
}

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.625rem);
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.625rem);
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.625rem);
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.625rem);
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.625rem);
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.625rem);
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.625rem);
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.625rem);
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.625rem);
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.625rem);
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.625rem);
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.625rem);
  }
}

.grid-y {
  display: flex;
  flex-flow: column nowrap;
}

.grid-y > .cell {
  width: auto;
}

.grid-y > .auto {
  height: auto;
}

.grid-y > .shrink {
  height: auto;
}

.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}

.grid-y > .small-1 {
  height: 8.33333%;
}

.grid-y > .small-2 {
  height: 16.66667%;
}

.grid-y > .small-3 {
  height: 25%;
}

.grid-y > .small-4 {
  height: 33.33333%;
}

.grid-y > .small-5 {
  height: 41.66667%;
}

.grid-y > .small-6 {
  height: 50%;
}

.grid-y > .small-7 {
  height: 58.33333%;
}

.grid-y > .small-8 {
  height: 66.66667%;
}

.grid-y > .small-9 {
  height: 75%;
}

.grid-y > .small-10 {
  height: 83.33333%;
}

.grid-y > .small-11 {
  height: 91.66667%;
}

.grid-y > .small-12 {
  height: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.33333%;
  }
  .grid-y > .medium-2 {
    height: 16.66667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.33333%;
  }
  .grid-y > .medium-5 {
    height: 41.66667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.33333%;
  }
  .grid-y > .medium-8 {
    height: 66.66667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.33333%;
  }
  .grid-y > .medium-11 {
    height: 91.66667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.33333%;
  }
  .grid-y > .large-2 {
    height: 16.66667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.33333%;
  }
  .grid-y > .large-5 {
    height: 41.66667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.33333%;
  }
  .grid-y > .large-8 {
    height: 66.66667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.33333%;
  }
  .grid-y > .large-11 {
    height: 91.66667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
}

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 0.625rem);
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.33333% - 0.625rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.66667% - 0.625rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 0.625rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.33333% - 0.625rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.66667% - 0.625rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 0.625rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.33333% - 0.625rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.66667% - 0.625rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 0.625rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.33333% - 0.625rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.66667% - 0.625rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 0.625rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.25rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.25rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.25rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.25rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 1.25rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.25rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 1.25rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 1.25rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.25rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 1.25rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 1.25rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.25rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 1.25rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 1.25rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.25rem);
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .medium-grid-frame {
    width: 100%;
  }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
}

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .large-grid-frame {
    width: 100%;
  }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
}

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
}

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 0.625rem);
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.33333% - 0.625rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.66667% - 0.625rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 0.625rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.33333% - 0.625rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.66667% - 0.625rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 0.625rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.33333% - 0.625rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.66667% - 0.625rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 0.625rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.33333% - 0.625rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.66667% - 0.625rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 0.625rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.25rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.25rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.25rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 1.25rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 1.25rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.25rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 1.25rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.25rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 1.25rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 1.25rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.25rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 1.25rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 1.25rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.25rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 1.25rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 1.25rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.25rem);
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 0.625rem);
}

@media print, screen and (min-width: 40em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.25rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.25rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.25rem);
  }
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

@media screen and (min-width: 40em) {
  .medium-align-left {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 64em) {
  .large-align-left {
    justify-content: flex-start;
  }
}

/* Blue */
/* Gray */
@font-face {
  font-family: 'Helvetica Neue LT W01_51488890';
  src: url("Fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
  src: url("Fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"), url("Fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"), url("Fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"), url("Fonts/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype");
}

@font-face {
  font-family: 'Helvetica Neue LT W01_75 Bold';
  src: url("Fonts/9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix");
  src: url("Fonts/9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix") format("eot"), url("Fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2") format("woff2"), url("Fonts/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff") format("woff"), url("Fonts/ae36b4a0-aa2c-46cc-98d8-d8b7d22241dc.ttf") format("truetype");
}

@font-face {
  font-family: 'Helvetica Neue LT W01_71488920';
  src: url("Fonts/52b6ae3e-9443-4acc-9bf9-f5154eeab77c.eot?#iefix");
  src: url("Fonts/52b6ae3e-9443-4acc-9bf9-f5154eeab77c.eot?#iefix") format("eot"), url("Fonts/ade4dbae-c607-4c84-a375-f0c4de0cc357.woff2") format("woff2"), url("Fonts/5b864741-6f14-4ed9-a297-27a4d73edf51.woff") format("woff"), url("Fonts/0cdccad5-a023-4a17-8ea9-01a0e6ad7ff4.ttf") format("truetype");
}

/*%heading-font-styles {
  line-height: $headings-line-height;
  font-weight: normal;
  font-family: $font-headings;
}*/
/*
%h1-font-size {
  @include resprop(
    'font-size',
    ($h1-font-size * 0.55, $h1-font-size * 0.85, $h1-font-size)
  );
  margin-bottom: 0.42em;
}

%h2-font-size {
  @include resprop('font-size', ($h2-font-size * 0.6, $h2-font-size));
}

%h3-font-size {
  @include resprop('font-size', ($body-font-size, $h3-font-size));
}

%h4-font-size {
  @include resprop('font-size', ($body-font-size, $h4-font-size));
}*/
h1,
h2,
h3,
h4 {
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
  font-weight: normal;
  color: #202020;
}

h1.centered,
h2.centered,
h3.centered,
p.centered {
  text-align: center;
}

h1 {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  line-height: normal;
}

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 2.5rem;
  }
  .article-detail h1 {
    font-size: 2rem;
  }
}

h1 a {
  color: #202020;
}

h1 a:hover {
  border-bottom: none;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  line-height: normal;
}

@media print, screen and (min-width: 40em) {
  h2 {
    font-size: 2rem;
  }
  .article-detail h2 {
    font-size: 1.6rem;
  }
}

h2 a {
  color: #202020;
}

h2 a:hover {
  border-bottom: none;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: normal;
}

@media print, screen and (min-width: 40em) {
  h3 {
    font-size: 1.5rem;
  }
  .article-detail h3 {
    font-size: 1.35rem;
  }
}

h3 a {
  color: #202020;
}

.richtext__container h3 a:hover {
  border-bottom: none;
}

h4 {
  font-size: 1.125rem;
  line-height: normal;
}

@media print, screen and (min-width: 40em) {
  h4 {
    font-size: 1.25rem;
  }
}

body {
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #202020;
}

@media screen and (min-width: 40em) {
  body {
    font-size: 1rem;
  }
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: #202020;
}

.paragraph--normal {
  font-size: 1rem;
}

.border--bottom:after {
  content: '';
  height: 0.08rem;
  width: 8rem;
  background: #ffffff;
  margin-bottom: 0.35rem;
  margin-top: 0.35rem;
  display: block;
}

.footer-paragraph--bottom {
  font-size: 0.875rem;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
  color: #ffffff;
  margin-bottom: 0;
}

.paragraph--small, address,
time, .article__date, .articles__category, .articles__date {
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #202020;
  margin-bottom: 0;
}

.paragraph--medium {
  font-size: 0.875rem;
}

.paragraph--medium p {
  font-size: 0.875rem;
}

.stats-title--narrow {
  font-weight: 200;
  margin-bottom: 0;
  margin-top: 1rem;
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
}

.stats-paragraph {
  font-size: 0.625rem;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
  color: #646464;
}

@media print, screen and (min-width: 40em) {
  .stats-paragraph {
    font-size: 0.875rem;
  }
}

.subnav-paragraph {
  font-weight: bold;
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  line-height: 15px;
  margin-bottom: 0;
}

.no-decoration {
  margin: 0;
  list-style: none;
  padding: 0;
}

strong {
  font-weight: normal;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
}

.article__container p {
  margin-bottom: 2rem;
}

.navigation__toggle .stroke, .button-standard, .form--general button[type='submit'] {
  transition: 0.2s ease;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
}

body.nav-is-open {
  position: fixed;
}

.site-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  height: 100%;
}

.main-content {
  height: 100%;
  flex: 1 0 auto;
}

.page--standard .main-content {
  padding: 1.5rem 0;
}

@media screen and (min-width: 40em) {
  .page--standard .main-content {
    padding: 3rem 0;
  }
}

@media screen and (min-width: 64em) {
  .page--standard .main-content {
    padding: 5rem 0;
  }
}

a {
  color: #1a7cc4;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
}

p,
form,
table {
  margin-bottom: 1rem;
}

ul,
ol {
  margin-bottom: 1rem;
  padding-left: 1rem;
}

address,
time {
  font-style: normal;
}

.image {
  margin-bottom: 1rem;
}

.image__caption {
  font-weight: bold;
  margin-bottom: 0;
}

.image__copyright {
  font-style: italic;
  font-size: 0.875rem;
}

@media screen and (min-width: 40em) {
  .image__copyright {
    font-size: 1rem;
  }
}

.anchor {
  position: relative;
  top: -1.5rem;
}

.flex-video {
  margin-bottom: 1rem;
}

.snippet {
  padding: 0 1rem;
  margin: 1.5rem 0;
}

@media print, screen and (min-width: 40em) {
  .snippet {
    margin: 4rem 0;
    padding: 0;
  }
}

.snippet--small {
  padding: 0 1rem;
  margin: 0.75rem 0;
}

@media print, screen and (min-width: 40em) {
  .snippet--small {
    margin: 2rem 0;
    padding: 0;
  }
}

.margin--bottom {
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 40em) {
  .margin--bottom {
    margin-bottom: 2rem;
    padding: 0;
  }
}

.margin--top-less {
  margin-top: 0;
}

.margin--bottom-large {
  margin-bottom: 2rem;
}

@media print, screen and (min-width: 40em) {
  .margin--bottom-large {
    margin-bottom: 3rem;
    padding: 0;
  }
}

.snippet__header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
}

.snippet__header--white {
  color: #ffffff;
  margin-bottom: 0.8rem;
}

.snippet__header--white h2 {
  color: #ffffff;
}

.test {
  background: red;
}

hr {
  border: 0;
  border-bottom: 1px dashed #c1c1c1;
  margin: 0 1rem;
}

@media print, screen and (min-width: 40em) {
  hr {
    margin: 0;
  }
}

.subpage__richtext {
  padding-top: 1rem;
  margin-left: 0;
}

@media print, screen and (min-width: 40em) {
  .subpage__richtext {
    padding-left: 3rem;
  }
}

.subpages-arrow {
  margin-top: auto;
}

/*
.responsive-embed {
  margin-left: 1.3rem;
  margin-right: 1.3rem;
  @include breakpoint(medium) {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
*/
.form--general {
  display: flex;
  flex-wrap: wrap;
}

.form--general:after {
  content: '';
  flex: auto;
}

.form--general .widget--textinput,
.form--general .widget--emailinput {
  display: flex;
  flex-flow: column;
  flex: 20em;
  margin: 0.1em;
  padding: 0.25em 0.5em 0.25em 0;
}

.form--general .widget--emailinput {
  flex: 100%;
}

.form--general label {
  display: inline-block;
  margin-bottom: 0.25rem;
}

.form--general label.required::after {
  content: ' *';
}

.form--general label[for='id_captcha'],
.form--general .widget--recaptcha {
  display: none;
}

.form--general .g-recaptcha {
  margin: 0.1em;
  padding: 0.25em 0.5em 0.25em 0;
  width: 100%;
}

.form--general button[type='submit'] {
  height: 4rem;
  margin: 0.5em 0.1em;
  padding: 0.25em 0.5em 0.25em 0;
}

.form--general input,
.form--general textarea {
  display: block;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border: 1px solid #c1c1c1;
  width: 100%;
}

@media screen and (min-width: 40em) {
  .form--general input,
  .form--general textarea {
    padding: 0.75rem 1rem;
  }
}

.form--general select {
  display: block;
  margin-bottom: 1rem;
}

.form--general .button {
  margin-top: 1rem;
}

.error input,
.error textarea {
  margin-bottom: 0;
}

.form__errors {
  margin-bottom: 1rem;
  font-weight: bold;
}

.errorlist {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  list-style: none;
  color: red;
  font-size: 0.875rem;
  width: 100%;
}

.errorlist li::before {
  content: '* ';
}

.widget--textinput,
.widget--emailinput,
.widget--textarea {
  width: 100%;
}

.newsletter__snippet {
  margin-bottom: 1rem;
}

.form--submit-text {
  margin-top: 3rem;
}

.form__newsletter {
  display: flex;
  flex-direction: column;
}

.newsletter__container {
  display: flex;
  margin: 0 auto;
  width: 90%;
}

@media print, screen and (min-width: 64em) {
  .newsletter__container {
    width: 100%;
  }
}

.newsletter__header {
  text-align: center;
  margin-bottom: 2rem;
}

.newsletter__input {
  flex: 1 1 70%;
}

.newsletter__input input {
  border: none;
  background-color: #f7f7f7;
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.17);
  text-align: center;
  border-top-left-radius: 0.5625rem;
  border-bottom-left-radius: 0.5625rem;
  height: 3rem;
}

.newsletter__input input:focus {
  outline: none;
}

.newsletter__submit {
  flex: 1 1 30%;
}

.newsletter__submit .newsletter__button {
  display: block;
  color: #ffffff;
  border-top-right-radius: 0.5625rem;
  border-bottom-right-radius: 0.5625rem;
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.17);
  height: 3.1rem;
  text-transform: uppercase;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
  font-size: 0.75rem;
  padding: 1.0625rem 1.75rem 1.1875rem;
  line-height: 1;
  transition: 0.15s ease-in-out;
  transition-property: background-color, box-shadow;
  background-color: #1a7cc4;
}

.header {
  padding-top: 6rem;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: #ffffff;
  transition: 0.2s ease box-shadow, 0.2s ease background-color;
  box-shadow: 0 1px 3px -2px #202020;
  max-height: 6rem;
}

.scrolled .sticky-header {
  box-shadow: 0 1px 5px -2px #202020;
}

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

@media print, screen and (min-width: 64em) {
  .navigation__social {
    padding: 0.5rem 0 0 3rem;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .navigation__social {
    padding: 0.5rem 0 0 1.5rem;
  }
}

@media screen and (max-width: 39.9375em) {
  .navigation__social {
    padding: 1.5rem 0 5rem 0;
  }
}

@media print, screen and (min-width: 64em) {
  .navigation__social .navigation__social-link {
    padding: 0 0 0 1rem;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .navigation__social .navigation__social-link {
    padding: 0 0 0 0.5rem;
  }
}

@media screen and (max-width: 39.9375em) {
  .navigation__social .navigation__social-link {
    padding: 0 0.75rem 0 0;
  }
}

.navigation__social .navigation__social-link svg {
  height: 2rem;
  width: 2rem;
  fill: #1a7cc4;
  transition: fill 200ms ease-in-out, background-color 200ms ease-in-out;
}

.navigation__social .navigation__social-link svg .social-border {
  fill: #1a7cc4;
}

.navigation__social .navigation__social-link svg.social-background {
  fill: #1a7cc4;
}

.navigation__social .navigation__social-link svg:hover {
  background-color: #1a7cc4;
  fill: #ffffff;
}

.navigation__list {
  left: -4px;
  margin: 0 0 0 auto;
  list-style: none;
  z-index: 0;
  position: absolute;
  top: 100%;
  width: 102%;
  padding: 0.5rem 2rem;
  transition: 0.25s ease-in-out;
  transition-property: opacity transform;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  background: #ffffff;
}

@media screen and (max-width: 39.9375em) {
  .navigation__list {
    height: calc(100vh - 5.6rem);
    overflow: auto;
  }
}

.nav-is-open .navigation__list {
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 40em) {
  .navigation__list {
    position: static;
    flex-direction: row;
    width: auto;
    padding: 0;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    box-shadow: none;
    left: 0;
    min-height: 0;
    display: flex;
  }
}

.navigation__breadcrumbs {
  padding: 1rem 0;
  border-bottom: 1px dashed #c1c1c1;
  border-top: 1px dashed #c1c1c1;
  text-align: right;
}

@media print, screen and (min-width: 40em) {
  .navigation__breadcrumbs {
    display: none;
  }
}

.navigation__breadcrumbs a:hover {
  border: 0;
}

.navigation__submenu {
  display: none;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px dashed #c1c1c1;
}

.navigation__submenu-item {
  padding: 0 1rem;
  margin-top: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  .navigation__submenu-item {
    display: none;
  }
}

.navigation__submenu-item a {
  font-weight: normal;
  color: #202020;
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
  text-transform: none;
}

.navigation__submenu-item a:hover {
  border-bottom: 0;
  color: #2e96e3;
}

.navigation__submenu-item .active {
  color: #1a7cc4;
}

@media screen and (max-width: 39.9375em) {
  .is--open {
    display: block;
  }
}

.rotate {
  transform: rotate(180deg);
}

/*
.navigation__item:hover .navigation__item-arrow {
  @include breakpoint(small only) {
    transform: rotate(180deg);
  }
}
*/
.navigation__item {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  list-style: none;
  border-bottom: 1px dashed #c1c1c1;
}

.navigation__item .navigation__item--top {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navigation__item .navigation__item-arrow {
  fill: #646464;
  height: 1rem;
  width: 1rem;
  transition: transform 200ms ease;
}

@media print, screen and (min-width: 40em) {
  .navigation__item .navigation__item-arrow {
    display: none;
  }
}

.navigation__item:first-child {
  border-top: 1px dashed #c1c1c1;
}

@media print, screen and (min-width: 40em) {
  .navigation__item:first-child {
    border-top: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .navigation__item {
    padding: 0;
    border-bottom: 0;
    border-top: 0;
  }
}

.navigation__item a {
  font-size: 1rem;
  color: #202020;
  transition: color 200ms ease;
}

@media print, screen and (min-width: 40em) {
  .navigation__item a {
    font-size: 0.875rem;
  }
}

.navigation__item a:hover {
  border-bottom: 0;
  color: #2e96e3;
}

.navigation__item .active {
  color: #1a7cc4;
}

@media screen and (min-width: 40em) {
  .navigation__item {
    margin-left: calc(4 * (1vw + 1vh - 1vmin));
    width: auto;
  }
  .navigation__item:first-child {
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .navigation__item {
    margin-left: 4rem;
    width: auto;
  }
}

.navigation__link .navigation__link-img {
  height: 58px;
  width: 55px;
  background-image: url("./img/logos/ensemble.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.scrolled-home-video .navigation__link .navigation__link-img {
  background-image: url("./img/logo-white.png");
}

.nav-is-open .navigation__link .navigation__link-img {
  background-image: url("./img/logos/ensemble.png");
}

.navigation__link:hover {
  border-bottom: none;
}

@media screen and (max-width: 39.9375em) {
  .navigation__link {
    margin-left: 1rem;
  }
}

.navigation__toggle {
  width: 2rem;
  height: 1.5rem;
  margin-left: auto;
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
}

.navigation__toggle:hover {
  border-bottom: none;
}

.navigation__toggle .stroke {
  display: block;
  position: absolute;
  height: 0.25rem;
  width: 100%;
  background-color: #202020;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  transition-property: transform, opacity, top, left;
  will-change: transform, opacity, top, left;
}

.scrolled-home-video .navigation__toggle .stroke {
  background-color: #ffffff;
}

.nav-is-open .navigation__toggle .stroke {
  background-color: #202020;
}

.navigation__toggle .stroke:nth-child(1) {
  top: 0;
  transform-origin: left center;
}

.navigation__toggle .stroke:nth-child(2) {
  top: 0.625rem;
  transform-origin: left center;
}

.navigation__toggle .stroke:nth-child(3) {
  top: 1.25rem;
  transform-origin: left center;
}

.nav-is-open .navigation__toggle .stroke:nth-child(1) {
  transform: rotate(45deg);
  top: -2px;
  left: 0px;
}

.nav-is-open .navigation__toggle .stroke:nth-child(2) {
  opacity: 0;
}

.nav-is-open .navigation__toggle .stroke:nth-child(3) {
  transform: rotate(-45deg);
  top: 1.3125rem;
  left: 0px;
}

@media screen and (min-width: 40em) {
  .navigation__toggle {
    display: none;
  }
}

.footer .footer--big {
  color: #ffffff;
  background-color: #44a2e6;
  padding: 2.5rem 0;
  font-size: 0.875rem;
}

.footer .footer--big .paragraph--small, .footer .footer--big address,
.footer .footer--big time, .footer .footer--big .article__date, .footer .footer--big .articles__category, .footer .footer--big .articles__date,
.footer .footer--big h4 {
  color: #ffffff;
}

@media screen and (max-width: 39.9375em) {
  .footer .footer--big .footer-left,
  .footer .footer--big .footer-navi {
    padding-bottom: 2rem;
    border-bottom: 1px dashed #c1c1c1;
  }
  .footer .footer--big .footer-left .footer-left__content,
  .footer .footer--big .footer-left .footer-right__content,
  .footer .footer--big .footer-navi .footer-left__content,
  .footer .footer--big .footer-navi .footer-right__content {
    padding: 0 1rem;
  }
  .footer .footer--big .footer-navi {
    padding-top: 2rem;
  }
}

.footer .footer-small {
  color: #ffffff;
  background-color: #1a7cc4;
  padding: 1rem 0;
}

.footer .footer-small .footer-small__container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 39.9375em) {
  .footer .footer-small .footer-small__container {
    flex-direction: column;
    text-align: center;
  }
  .footer .footer-small .footer-small__container .footer-small--first {
    padding-bottom: 1rem;
  }
}

.footer-left a {
  color: #202020;
}

.footer-navi__list-item {
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.footer-navi__list-item a {
  color: #ffffff;
}

.footer-navi__content {
  display: flex;
}

@media print, screen and (min-width: 40em) {
  .footer-navi__content {
    justify-content: flex-end;
  }
}

.button-standard, .form--general button[type='submit'] {
  transition-property: opacity;
  display: inline-block;
  padding: 0.5rem 2rem;
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 1rem;
  background-color: #1a7cc4;
}

.button-standard:hover, .form--general button:hover[type='submit'], .button-standard:focus, .form--general button:focus[type='submit'] {
  opacity: 0.7;
  border-bottom: none;
}

.button-wrapper {
  margin-bottom: 1rem;
}

@media screen and (min-width: 40em) {
  .button-wrapper {
    display: flex;
    align-items: center;
  }
  .button-wrapper .button-standard, .button-wrapper .form--general button[type='submit'], .form--general .button-wrapper button[type='submit'] {
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.button-wrapper__label {
  display: block;
  font-size: 0.875rem;
}

@media screen and (min-width: 40em) {
  .button-wrapper__label {
    font-size: 1rem;
  }
}

.button {
  display: inline-block;
  color: #ffffff;
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.17);
  border-radius: 0.5625rem;
  text-transform: uppercase;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
  font-size: 0.75rem;
  padding: 1.0625rem 1.75rem 1.1875rem;
  line-height: 1;
  transition: 0.15s ease-in-out;
  transition-property: background-color, box-shadow;
  animation: fade-in 1s ease-out alternate forwards;
  animation-delay: 0.6s;
  opacity: 0;
  background-color: #1a7cc4;
}

.button:hover {
  background-color: #2e96e3;
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.33);
}

.button.button--yellow {
  color: #202020;
  background-color: #f8da22;
}

.button.button--yellow:hover {
  background-color: #fae253;
}

.button.button--center {
  margin: 0 auto;
  padding: 1.0625rem 2rem 1.1875rem;
}

.button.button--margin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 40em) {
  .button.button--margin {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.logos--plain {
  padding-top: 1rem;
}

.logos__title {
  display: block;
  margin-bottom: 1rem;
}

.logos__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.logos__wrapper .logo {
  margin: 0 0.65rem 1.25rem;
  height: 4rem;
  width: 5rem;
}

.logos__wrapper .logo--aqua-viva,
.logos__wrapper .logo--fkz {
  width: 6.5rem;
}

@media screen and (min-width: 40em) {
  .logos__wrapper .logo {
    height: 5rem;
    width: 6rem;
  }
  .logos__wrapper .logo--aqua-viva,
  .logos__wrapper .logo--fkz {
    width: 8rem;
  }
}

@media screen and (min-width: 64em) {
  .logos__wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .logos__wrapper .logo {
    height: 4.5rem;
    width: auto;
    margin: 0;
  }
  .logos__wrapper .logo--aqua-viva {
    height: 3rem;
    width: auto;
  }
  .logos__wrapper .logo--fkz {
    height: 3.8rem;
    width: auto;
  }
}

.partners__list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.partners__item {
  flex: 1 1 100%;
  max-width: 100%;
  min-height: 6rem;
  list-style: none;
}

@media print, screen and (min-width: 40em) {
  .partners__item {
    flex: 1 1 50%;
    max-width: 50%;
    min-height: 8.4375rem;
  }
}

.partners__content {
  padding-left: 3rem;
  padding-bottom: 1rem;
  hyphens: auto;
}

.partners__content p {
  padding-top: 0.15rem;
  margin-bottom: 0;
}

.partners__logo img {
  max-height: 6rem;
}

.partners__logo-gc {
  margin-top: -1rem;
}

.subpages__container {
  padding-left: 0;
}

.subpages__container li {
  list-style: none;
}

.subpages__container .subpages__item {
  background: #f7f7f7;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  hyphens: auto;
}

.subpages__container .subpages__item .subpages-arrow a:hover {
  border-bottom: none;
}

.icon__library {
  display: none;
}

.stats__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  hyphens: auto;
}

@media screen and (max-width: 39.9375em) {
  .stats__content {
    margin-bottom: 0.5rem;
  }
}

.stats__content .icon {
  height: 100px;
  width: 100px;
  fill: #646464;
}

.teaser-small {
  border-bottom: 1px dashed #c1c1c1;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.teaser-small:first-of-type {
  padding-top: 0;
}

@media screen and (max-width: 39.9375em) {
  .teaser-small:first-of-type {
    border-top: 1px dashed #c1c1c1;
    padding-top: 1rem;
  }
}

.teaser-small:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}

.teaser-small__container {
  display: flex;
  flex-direction: row;
}

.teaser-small__container .paragraph--medium {
  padding-right: 0.5rem;
  hyphens: auto;
  flex: 1 0 50%;
}

.teaser-small__container .teaser-small__image {
  flex: 1 0 50%;
}

.teaser-big {
  padding-right: 2rem;
  height: 100%;
  border-right: 1px dashed #c1c1c1;
}

@media screen and (max-width: 39.9375em) {
  .teaser-big {
    border-right: none;
    padding-right: 0;
    height: auto;
  }
}

.teaser-big h1 {
  padding-right: 1rem;
}

.teaser-big__container {
  flex-direction: column;
}

@media screen and (max-width: 39.9375em) {
  .teaser-big__container {
    display: block;
  }
}

.teaser-big__container .paragraph--medium {
  hyphens: auto;
  padding-bottom: 1rem;
  flex: 1 0 50%;
}

@media screen and (max-width: 39.9375em) {
  .teaser-big__container .teaser-big__image {
    padding-bottom: 1rem;
  }
}

.teaser-button__container {
  margin-bottom: 0.5rem;
  transform: translate(0%, -25%);
}

.teaser-button {
  font-size: 8px;
  letter-spacing: 1px;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  border: 1px solid #1a7cc4;
  transition: background-color 200ms ease, color 200ms ease;
}

.teaser-button:hover {
  background-color: #1a7cc4;
  color: #ffffff;
}

.richtext__image-description {
  margin-bottom: 1rem;
  margin-top: 0.25rem;
}

.richtext__header {
  overflow: hidden;
  margin: 0;
  width: 100%;
}

.richtext__image--big {
  width: 100%;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}

.richtext__image--small {
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}

.richtext__image.richtext__image--right {
  border-radius: 0.8rem;
}

.richtext__image.richtext__image--left {
  border-radius: 0.8rem;
}

@media print, screen and (min-width: 40em) {
  .richtext-left {
    padding-right: 2rem;
  }
}

@media print, screen and (min-width: 40em) {
  .richtext-right {
    padding-left: 2rem;
  }
}

@media print, screen and (min-width: 40em) {
  .richtext__container p {
    margin-bottom: 2rem;
  }
}

.subheader {
  display: flex;
  justify-content: space-between;
}

.back-button:hover {
  border: 0;
}

@media screen and (max-width: 39.9375em) {
  .back-button svg {
    max-width: 8rem;
    margin-right: 2rem;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
}

.breadcrumbs span {
  vertical-align: middle;
  font-size: 0.625rem;
}

.breadcrumbs a:hover {
  border: 0;
}

.breadcrumb--active {
  color: #c1c1c1;
}

.sidenav:last-of-type {
  border-bottom: 1px dashed #c1c1c1;
}

.sidenav > a {
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
}

.sidenav__item {
  border-top: 1px dashed #c1c1c1;
  padding: 1rem 0.5rem;
  line-height: 1;
  transition: background-color 200ms ease;
}

.sidenav__item:hover {
  background-color: #1a7cc4;
  color: #ffffff;
  border-top: 1px dashed transparent;
}

.sidenav__item:hover :last-of-type {
  border: 0;
}

.sidenav--active {
  position: relative;
  background-color: #1a7cc4;
  border-top: 1px dashed transparent;
  z-index: 100;
  margin-bottom: -1px;
}

.sidenav--active p {
  color: #ffffff;
}

.teaser__column .teaser-small__image {
  margin-bottom: 1rem;
}

@media screen and (max-width: 39.9375em) {
  .teaser__column {
    border-top: 1px dashed #c1c1c1;
    padding-top: 2rem;
    margin-bottom: 2rem;
    overflow: hidden;
  }
}

.teaser__wrapper .cell:first-child .teaser__column {
  border-top: 0;
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: 0;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable {
  tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.timeline__item {
  transition: all 0.3s ease-in-out;
  width: 20rem;
  border-radius: 0.8rem;
  min-height: 17rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 1rem 0.5rem 2rem 0.5rem;
}

@media print, screen and (min-width: 40em) {
  .timeline__item {
    margin: 8rem 3rem 4rem 3rem;
  }
  .timeline__item.is-selected {
    transform: translateY(-20%);
  }
  .timeline__item.timeline__item--up {
    transform: translateY(-20%);
  }
}

.timeline__item.is-selected {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media print, screen and (min-width: 40em) {
  .timeline__item--odd.timeline__item--up {
    transform: translateY(-20%);
  }
}

.timeline__date {
  background: #1a7cc4;
  color: #ffffff;
  padding: 1rem;
  width: 100%;
  display: block;
  font-weight: bold;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  z-index: 10000;
  position: relative;
}

.timeline--past .timeline__date {
  background: lightgrey;
}

.timeline__box {
  padding: 1rem;
}

.timeline__line {
  position: absolute;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease-in-out;
  z-index: -100;
}

@media screen and (max-width: 39.9375em) {
  .timeline__line {
    display: none;
  }
}

.timeline__line--right {
  top: -20px;
  right: -250px;
  transform-origin: 0 100%;
  height: 55px;
  width: 218px;
  background-image: url("./img/line.svg");
  background-repeat: no-repeat;
  z-index: -100;
}

.timeline--past .timeline__line--right {
  background-image: url("./img/line-past.svg");
}

.timeline__item--up .timeline__line--right {
  transform: translate(-50%, -50%) rotate(34deg);
}

.timeline__item--last .timeline__line--right {
  display: none;
}

.timeline__line--left {
  top: -20px;
  left: -35px;
  transform-origin: 100% 100%;
  height: 55px;
  width: 218px;
  background-image: url("./img/line-left.svg");
  background-repeat: no-repeat;
  z-index: -100;
}

.timeline--past .timeline__line--left {
  background-image: url("./img/line-left-past.svg");
}

.timeline__item--up .timeline__line--left {
  transform: translate(-50%, -50%) rotate(-34deg);
}

.timeline__item--first .timeline__line--left {
  display: none;
}

.timeline__item--even .timeline__line {
  display: none;
}

.timeline--past .timeline__title, .timeline--past
.timeline__text {
  color: #646464;
}

.download__container {
  border-top: 1px dashed #c1c1c1;
  width: 100%;
}

.download__container:last-child {
  border-bottom: 1px dashed #c1c1c1;
}

.download__link {
  padding: 1.5rem 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: background-color 200ms ease;
}

.download__link:hover {
  background-color: #f7f7f7;
}

.download__content .text-uppercase {
  text-transform: uppercase;
}

.download__content h3 {
  margin-bottom: 0.5rem;
}

.download__content p {
  color: #202020;
  font-weight: normal;
  margin-bottom: 0;
}

.download__icon {
  margin-top: auto;
  margin-bottom: auto;
}

.icon--download {
  color: #1a7cc4;
  height: 30px;
  width: auto;
}

.faq-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.js-foldout-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.faq-list {
  margin-bottom: 4rem;
}

.faq-margin {
  margin: 0 0.38rem 3rem 0.38rem;
}

@media screen and (max-width: 39.9375em) {
  .faq-margin {
    margin: 0 1.5rem 2rem 1.5rem;
  }
}

.faq-column {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.faq {
  border: 0px solid #c1c1c1;
  border-top-width: 1px;
  width: 100%;
  display: table-row;
  border-bottom-width: 1px;
}

.arrow--vertical {
  color: #1a7cc4;
}

.faq__header {
  cursor: pointer;
  padding: 0.4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq__header::after {
  content: '';
  padding: 1rem;
  background: url("img/arrow-vertical-blue.svg") center/50% no-repeat;
  transform: rotate(-90deg);
  transition: transform 200ms ease-in-out;
}

.toggled .faq__header::after {
  transform: rotate(-180deg);
}

.faq__contents {
  border: 0px solid #c1c1c1;
  border-top-width: 1px;
  padding: 1rem 0 0.1rem 0;
}

.faq__contents .snippet--small {
  padding: 0;
}

.faq-title {
  margin: 1rem 0;
}

.building-map {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .building-map {
    width: 80%;
  }
}

.square {
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
}

.legend-container {
  display: flex;
}

.legend-container:first-of-type {
  padding: 0.8rem 0 0 0;
}

.legend-container .square {
  margin-right: 1.3rem;
}

.legend-container .legend-caption {
  font-family: "Helvetica Neue LT W01_71488920", sans-serif;
  font-size: 14px;
}

.legend-container .arrows {
  margin-right: 0.8rem;
  height: 1.5rem;
  width: 2rem;
}

/* Styles for the home page*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home .header {
  padding-top: 0;
}

.home .sticky-header {
  background-color: #ffffff;
  opacity: 0;
  animation: fade-in 1s ease-out alternate forwards;
}

.home.scrolled-home-video .sticky-header {
  background-color: transparent;
  box-shadow: none;
}

@media print, screen and (min-width: 40em) {
  .home.scrolled-home-video .navigation__item a {
    color: #b2ddfe;
  }
  .home.scrolled-home-video .navigation__item a:hover {
    color: #ffffff;
  }
  .home.scrolled-home-video .navigation__social-link svg {
    fill: #b2ddfe;
  }
  .home.scrolled-home-video .navigation__social-link svg .social-border {
    fill: #b2ddfe;
  }
  .home.scrolled-home-video .navigation__social-link svg:hover {
    background-color: #b2ddfe;
    fill: #ffffff;
  }
}

.home.nav-is-open .sticky-header {
  background-color: #ffffff;
}

.home-video {
  overflow: hidden;
  position: relative;
  transition: opacity 0.2s ease-in-out;
  color: #ffffff;
}

.home-video p {
  font-size: 1rem;
  margin-bottom: 2rem;
  animation: fade-in 1s ease-out alternate forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

@media screen and (min-width: 40em) {
  .home-video p {
    font-size: 1.125rem;
  }
}

.home-video::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 124, 196, 0.8);
}

.home-video.show-video::after {
  background-color: rgba(26, 124, 196, 0);
}

.home-video__title {
  color: #ffffff;
  font-size: 2rem;
  animation: fade-in 1s ease-out alternate forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

@media screen and (min-width: 40em) {
  .home-video__title {
    font-size: 3rem;
  }
}

.home-video__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16 / 9) {
  .home-video__background {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16 / 9) {
  .home-video__background {
    width: 150%;
    left: -25%;
  }
}

.home-video__visibility-toggle {
  display: block;
  width: 1.75rem;
  padding: 0;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 20;
  transition: opacity 0.2s ease-in-out;
}

.home-video__visibility-toggle svg {
  fill: #ffffff;
}

.home-video__visibility-toggle.home-video__visibility-toggle--play {
  opacity: 1;
}

.show-video .home-video__visibility-toggle.home-video__visibility-toggle--play {
  opacity: 0;
}

.home-video__visibility-toggle.home-video__visibility-toggle--pause {
  opacity: 0;
}

.show-video .home-video__visibility-toggle.home-video__visibility-toggle--pause {
  opacity: 1;
}

.show-video [data-home-video-play] {
  cursor: auto;
}

.home-video__content {
  position: relative;
  z-index: 10;
  padding-top: 15vh;
  padding-bottom: 12vh;
  transition: 0.2s ease-in-out opacity;
}

@media print, screen and (min-width: 40em) {
  .home-video__content {
    padding-top: 24vh;
    padding-bottom: 24vh;
  }
}

.show-video .home-video__content {
  opacity: 0;
}

.featured-subpage__container {
  position: relative;
}

.card__link {
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
}

.card__container {
  position: relative;
  min-height: 250px;
  margin: 0 auto;
}

@media print, screen and (min-width: 40em) {
  .card__container {
    min-height: 300px;
  }
}

.card {
  position: relative;
  transition: 0.6s;
}

.card__content {
  background: #1a7cc4;
  color: #fff;
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  text-align: left;
  cursor: pointer;
  transition: 0.6s;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 100%;
  border-radius: 0.8rem;
}

@media print, screen and (min-width: 40em) {
  .card__content:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    opacity: 1;
  }
  .card__content:hover .card__caption:before {
    opacity: 1;
  }
  .card__content:hover h3 {
    color: #fff;
    transform: translateY(-58%);
  }
}

@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .card__content:hover h3 {
    transform: translateY(-48%);
  }
}

@media print, screen and (min-width: 40em) {
  .card__content:hover p {
    opacity: 1;
    transform: translateY(30%);
  }
}

@media print, screen and (min-width: 40em) and (min-width: 64em) {
  .card__content:hover p {
    transform: translateY(30%);
  }
}

@media print, screen and (min-width: 40em) {
  .card__content:hover .subpages-arrow {
    opacity: 1;
    transform: translateX(0);
  }
}

.card__content .card__image {
  position: relative;
  display: block;
  min-height: 100%;
  opacity: 0.7;
  height: 100%;
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .card__content .card__image.card__image--blocker {
    min-height: 328px;
  }
}

@media print, screen and (min-width: 64em) {
  .card__content .card__image.card__image--blocker {
    min-height: 286px;
  }
}

.card__content .card__caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card__content .card__caption:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 124, 196, 0.8);
  content: '';
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
}

.card__content .card__top-title {
  font-size: 9px;
  letter-spacing: 1px;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
  text-transform: uppercase;
  transition: background-color 200ms ease, color 200ms ease;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 0 0 1rem;
  color: #ffffff;
}

.card__content h3 {
  word-spacing: -0.15em;
  width: auto;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  align-items: flex-end;
  display: flex;
  color: #ffffff;
  transition: transform 0.35s, color 0.35s;
  padding-left: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  transform-origin: 0 100%;
}

@media print, screen and (min-width: 40em) {
  .card__content h3 {
    width: 13rem;
  }
}

@media screen and (max-width: 39.9375em) {
  .card__content h3 {
    font-size: 1rem;
    hyphens: auto;
  }
}

.card__content p {
  letter-spacing: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.5rem;
  transform: translate3d(0, 180px, 0);
  transition: opacity 0.35s, transform 0.35s;
  margin: 0;
  height: 100%;
  align-items: flex-start;
  display: flex;
}

@media print, screen and (min-width: 64em) {
  .card__content p {
    font-size: 75%;
    transform: translate3d(0, 100px, 0);
  }
}

.card__content .subpages-arrow {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  padding: 0 0.8rem 0.5rem 0;
  width: 100%;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.35s, transform 0.35s;
  margin: 0;
}

.card__content .subpages-arrow img {
  float: right;
}

.card__button {
  margin-top: 1rem;
}

.articles__snippet {
  padding: 2.5rem 0;
  width: 100%;
  background: #1a7cc4;
  background: linear-gradient(0deg, #1d1b37 0%, #1a7cc4 81%, #1a7cc4 100%);
}

.articles__image {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.8rem;
  transition: 0.6s;
  width: 100%;
}

.articles__image.articles__image--large {
  box-shadow: none;
}

.articles__image.articles__image--news {
  transition: none;
}

@media print, screen and (min-width: 40em) {
  .articles__link:hover .articles__image {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

@media screen and (max-width: 39.9375em) {
  .articles__item {
    margin-bottom: 2rem;
  }
}

.articles__item.articles__item--small {
  overflow: hidden;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

@media print, screen and (min-width: 64em) {
  .articles__item.articles__item--small {
    display: flex;
    padding-bottom: 0;
  }
  .articles__item.articles__item--small .articles__image-container {
    flex: 1 0 33%;
    margin-bottom: 2rem;
    margin-right: 1rem;
  }
}

.articles__image-container {
  margin-bottom: 0.5rem;
}

.article__date, .articles__category, .articles__date {
  color: #f7f7f7;
  margin-bottom: 0.5rem;
  display: block;
}

@media print, screen and (min-width: 40em) {
  .article__date, .articles__category, .articles__date {
    margin-bottom: 1rem;
  }
}

.articles__content {
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.articles__excerpt {
  font-family: "Helvetica Neue LT W01_51488890", sans-serif;
  color: #202020;
}

.articles__excerpt.articles__excerpt--white {
  color: #ffffff;
}

.articles {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

.articles.article-grid--others {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@supports (grid-area: auto) {
  .articles {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 20px;
  }
}

.articles > .articles__article {
  flex: 1 1 100%;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 40em) {
  .articles > .articles__article {
    flex: 0 1 32%;
  }
}

.articles__article {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
}

.articles__article .articles__image-wrapper {
  position: relative;
  padding-bottom: 61%;
  margin-bottom: 1rem;
}

.articles__article .articles__image-wrapper .articles__image {
  position: absolute;
  box-shadow: none;
}

.articles__article .teaser__content {
  hyphens: auto;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  position: relative;
  z-index: 1000;
  height: auto;
}

.articles__article .teaser__content .teaser__title {
  margin-bottom: 0.75rem;
}

@media print, screen and (min-width: 40em) {
  .articles__article-content {
    border-bottom: 2px solid #c1c1c1;
  }
}

.articles__article--no-border .articles__article-content {
  border: none;
}

.articles__header {
  display: flex;
}

.articles__category {
  background: #1a7cc4;
  padding: 0.35rem 0.75rem;
  margin-right: 1.5rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
  font-family: "Helvetica Neue LT W01_75 Bold", sans-serif;
}

.articles__category.articles__category--white {
  color: #1a7cc4;
  background: #ffffff;
}

.articles__date {
  color: #646464;
  align-self: center;
}

.articles__date.articles__date--white {
  color: #ffffff;
}

.news__button {
  text-align: center;
}

.articles-detail__image-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.gallery-container {
  margin: 1rem 0 3rem 0;
}

@media screen and (max-width: 63.9375em) {
  .gallery-container {
    overflow: hidden;
  }
}

.gallery-slide {
  position: relative;
}

.gallery-slide--none {
  background-color: transparent;
}

.gallery__slides {
  overflow-x: hidden;
}

.gallery__slider {
  display: flex;
}

.gallery__slide {
  color: white;
  position: relative;
  overflow: visible;
  display: flex;
}

@media print, screen and (min-width: 40em) {
  .gallery__slide:hover .gallery__description {
    opacity: 1;
    transform: translateX(0);
  }
}

.gallery__go-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.gallery-image__wrapper {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.gallery__image {
  display: block;
  width: 100%;
  max-height: 100%;
  border-radius: 0.8rem;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.gallery__description {
  position: absolute;
  bottom: 2.75rem;
  align-self: flex-end;
  z-index: 2;
  background-color: #1a7cc4;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  left: 0;
  min-height: 6rem;
  min-width: 15rem;
  padding: 2rem 6rem 2rem 2rem;
  overflow: hidden;
  transform: translateX(-1rem);
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .gallery__description {
    opacity: 1;
    transform: translateX(0);
    left: 4rem;
    bottom: 1rem;
    padding: 2rem 3rem 2rem 2rem;
    max-width: 50rem;
  }
}

@media screen and (max-width: 39.9375em) {
  .gallery__description {
    opacity: 1;
    transform: translateX(0);
    left: 0rem;
    bottom: 0rem;
    padding: 1rem 4rem 1rem 1rem;
    min-height: 4rem;
  }
}

.gallery__description .gallery__description-title {
  margin: 0;
  font-weight: bold;
}

.gallery__description .gallery__description-caption {
  margin-bottom: 0rem;
}

.gallery__go {
  position: relative;
  display: flex;
  font-size: 20px;
  line-height: 24px;
  color: #202020;
  font-weight: 700;
  padding-bottom: 19px;
  margin-bottom: 50px;
}

@media screen and (max-width: 39.9375em) {
  .gallery__go {
    margin-bottom: 0px;
  }
}

.gallery__go:hover {
  color: #1a7cc4;
  text-decoration: none;
}

.gallery__go:hover:after {
  top: calc(100% + 5px);
  border-color: #1a7cc4;
}

.gallery__go:after {
  content: '';
  width: 35px;
  height: 35px;
  border: solid #c1c1c1;
  border-width: 0 10px 10px 0;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -13px;
  transform: rotate(45deg);
  transition: all 0.3s;
}

@media screen and (max-width: 39.9375em) {
  .gallery__go:after {
    width: 19px;
    height: 19px;
    border-width: 0 5px 5px 0;
  }
}

.gallery__go[data-go='-1'] {
  position: absolute;
  display: block;
  top: 40%;
  left: 1rem;
  transform: rotate(90deg);
}

@media print, screen and (min-width: 64em) {
  .gallery__go[data-go='-1'] {
    left: 0;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .gallery__go[data-go='-1'] {
    left: 5rem;
    top: 13rem;
  }
}

@media screen and (max-width: 39.9375em) {
  .gallery__go[data-go='-1'] {
    display: none;
  }
}

.gallery__go[data-go='1'] {
  position: absolute;
  top: 40%;
  display: block;
  right: 1rem;
  transform: rotate(-90deg);
}

@media print, screen and (min-width: 64em) {
  .gallery__go[data-go='1'] {
    right: 0;
  }
}

@media screen and (max-width: 39.9375em) {
  .gallery__go[data-go='1'] {
    display: none;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .gallery__go {
    height: 100%;
  }
}

.gallery__thumbs {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
}

.gallery__thumbs [data-thumb] {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #c1c1c1;
  border-radius: 50%;
  margin: 3px 0.5rem;
}

.gallery__thumbs [data-current] {
  background-color: #1a7cc4;
}

.person__content h3 {
  margin-top: 0.25rem;
  text-align: center;
}

.person__content p {
  text-align: center;
}

.edit-tools {
  position: fixed;
  z-index: 10;
  right: 0.5rem;
  bottom: 0.5rem;
  background: #1a7cc4;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 0.6rem;
  color: #ffffff;
}

.edit-tools .button {
  background: #115180;
  font-size: 1em;
  margin: 0.25rem;
  padding: 0.5rem 0.6rem;
  display: inline-block;
  min-width: auto;
  border-radius: 0.6rem;
}

.show-breakpoint {
  position: fixed;
  opacity: 0.7;
  z-index: 10;
  right: 0.5rem;
  bottom: 4rem;
  color: #ffffff;
  font-weight: bold;
}

.show-breakpoint > div {
  padding: 0.1em 0.5em;
  border-radius: 0.6rem;
}

.show-breakpoint .show-for-small-only {
  background: lightseagreen;
}

.show-breakpoint .show-for-medium-only {
  background: orange;
}

.show-breakpoint .show-for-large {
  background: red;
}
