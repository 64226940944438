@import '~foundation-sites/scss/util/util';

$global-width: rem-calc(1024);

@import '~foundation-sites/scss/foundation';

$global-flexbox: true;

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px
);

$breakpoint-classes: (small medium large);

$grid-column-gutter: (
  small: 30px,
  medium: 30px
);

$grid-margin-gutters: (
  small: 10px,
  medium: 20px
);

@include foundation-xy-grid-classes;
@include foundation-responsive-embed;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-text-alignment;

.medium-align-left {
  @include breakpoint(medium up) {
    justify-content: flex-start;
  }
}

.large-align-left {
  @include breakpoint(large up) {
    justify-content: flex-start;
  }
}
