// Download
.download__container {
  border-top: 1px $general-border-style $medium-gray;

  width: 100%;
  &:last-child {
    border-bottom: 1px $general-border-style $medium-gray;
  }
}

.download__link {
  padding: 1.5rem 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: background-color 200ms ease;
  &:hover {
    background-color: $light-gray;
  }
}

.download__content {
  .text-uppercase {
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    color: $black;
    font-weight: normal;
    margin-bottom: 0;
  }
}

.download__icon {
  margin-top: auto;
  margin-bottom: auto;
}

.icon--download {
  color: $moderate-blue;
  height: 30px;
  width: auto;
}
