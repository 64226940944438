// Subpages Teaser
// =========

.subpages__container {
  padding-left: 0;

  li {
    list-style: none;
  }
  .subpages__item {
    background: $light-gray;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    hyphens: auto;

    .subpages-arrow {
      a {
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
}
