@font-face {
  font-family: 'Helvetica Neue LT W01_51488890';
  src: url('Fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix');
  src: url('Fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix')
      format('eot'),
    url('Fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2') format('woff2'),
    url('Fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff') format('woff'),
    url('Fonts/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica Neue LT W01_75 Bold';
  src: url('Fonts/9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix');
  src: url('Fonts/9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix')
      format('eot'),
    url('Fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2') format('woff2'),
    url('Fonts/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff') format('woff'),
    url('Fonts/ae36b4a0-aa2c-46cc-98d8-d8b7d22241dc.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica Neue LT W01_71488920';
  src: url('Fonts/52b6ae3e-9443-4acc-9bf9-f5154eeab77c.eot?#iefix');
  src: url('Fonts/52b6ae3e-9443-4acc-9bf9-f5154eeab77c.eot?#iefix')
      format('eot'),
    url('Fonts/ade4dbae-c607-4c84-a375-f0c4de0cc357.woff2') format('woff2'),
    url('Fonts/5b864741-6f14-4ed9-a297-27a4d73edf51.woff') format('woff'),
    url('Fonts/0cdccad5-a023-4a17-8ea9-01a0e6ad7ff4.ttf') format('truetype');
}

$font-main: 'Helvetica Neue LT W01_51488890', sans-serif;
$font-bold: 'Helvetica Neue LT W01_75 Bold', sans-serif;
$font-narrow: 'Helvetica Neue LT W01_51488890', sans-serif;
$font-headings: 'Helvetica Neue LT W01_51488890', sans-serif;
$font-italic: 'Helvetica Neue LT W01_71488920', sans-serif;

$body-font-size-tiny: rem-calc(10);
$body-font-size-small: rem-calc(14);
$body-font-medium: rem-calc(14);
$body-font-size: rem-calc(16);
$body-line-height: 1.5;
$headings-line-height: 1.09;

$h1-font-size: rem-calc(40px);
$h2-font-size: rem-calc(32px);
$h3-font-size: rem-calc(24px);
$h4-font-size: rem-calc(20px);
$h4-font-size-small: rem-calc(18px);

$general-block-margin: 1rem;
$general-padding: 2.5rem;
$general-border-style: dashed;

/*%heading-font-styles {
  line-height: $headings-line-height;
  font-weight: normal;
  font-family: $font-headings;
}*/
/*
%h1-font-size {
  @include resprop(
    'font-size',
    ($h1-font-size * 0.55, $h1-font-size * 0.85, $h1-font-size)
  );
  margin-bottom: 0.42em;
}

%h2-font-size {
  @include resprop('font-size', ($h2-font-size * 0.6, $h2-font-size));
}

%h3-font-size {
  @include resprop('font-size', ($body-font-size, $h3-font-size));
}

%h4-font-size {
  @include resprop('font-size', ($body-font-size, $h4-font-size));
}*/

h1,
h2,
h3,
h4 {
  font-family: $font-bold;
  font-weight: normal;
  color: $headings-color;
}

h1.centered,
h2.centered,
h3.centered,
p.centered {
  text-align: center;
}

h1 {
  font-size: $h2-font-size * 0.8;
  margin-bottom: 1.5rem;
  line-height: normal;
  @include breakpoint(medium) {
    font-size: $h1-font-size;
    .article-detail & {
      font-size: $h1-font-size * 0.8;
    }
  }
  a {
    color: $black;
    &:hover {
      border-bottom: none;
    }
  }
}

h2 {
  font-size: $h3-font-size;
  margin-bottom: 1.5rem;
  line-height: normal;
  @include breakpoint(medium) {
    font-size: $h2-font-size;
    .article-detail & {
      font-size: $h2-font-size * 0.8;
    }
  }
  a {
    color: $black;
    &:hover {
      border-bottom: none;
    }
  }
}

h3 {
  font-size: $h3-font-size * 0.8;
  margin-bottom: $general-block-margin;
  line-height: normal;
  @include breakpoint(medium) {
    font-size: $h3-font-size;
    .article-detail & {
      font-size: $h3-font-size * 0.9;
    }
  }
  a {
    color: $black;
    .richtext__container &:hover {
      border-bottom: none;
    }
  }
}

h4 {
  font-size: $h4-font-size-small;
  line-height: normal;
  @include breakpoint(medium) {
    font-size: $h4-font-size;
  }
  // margin-bottom: $general-block-margin;
}

// to be extended where it is
%indent-list-items {
  margin-left: 1em;
}
body {
  font-family: $font-main;
  font-size: $body-font-size-small;
  line-height: $body-line-height;
  color: $black;

  @include breakpoint(medium up) {
    font-size: $body-font-size;
  }
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: $black;
}

// Typo Classes

.paragraph--normal {
  font-size: $body-font-size;
}

.border--bottom {
  &:after {
    content: '';
    height: 0.08rem;
    width: 8rem;
    background: $white;
    margin-bottom: 0.35rem;
    margin-top: 0.35rem;
    display: block;
  }
}

.footer-paragraph--bottom {
  font-size: $body-font-size-small;
  font-family: $font-bold;
  color: $white;
  margin-bottom: 0;
}

.paragraph--small {
  font-family: $font-main;
  font-size: $body-font-size-small;
  line-height: $body-line-height;
  color: $black;
  margin-bottom: 0;
}

.paragraph--medium {
  font-size: $body-font-medium;
  p {
    font-size: $body-font-medium;
  }
}

.stats-title--narrow {
  font-weight: 200;
  margin-bottom: 0;
  margin-top: 1rem;
  font-family: $font-narrow;
}

.stats-paragraph {
  font-size: $body-font-size-tiny;
  font-family: $font-bold;
  color: $dark-gray;
  @include breakpoint(medium) {
    font-size: $body-font-medium;
  }
}

.subnav-paragraph {
  font-weight: bold;
  font-size: $body-font-size-tiny;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  line-height: 15px;
  margin-bottom: 0;
}

.no-decoration {
  margin: 0;
  list-style: none;
  padding: 0;
}

strong {
  font-weight: normal;
  font-family: $font-bold;
}

p {
  .article__container & {
    margin-bottom: 2rem;
  }
}
