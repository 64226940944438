// Richtext with image

.richtext__image-description {
  margin-bottom: 1rem;
  margin-top: 0.25rem;
}

.richtext__header {
  overflow: hidden;
  margin: 0;
  width: 100%;
}
.richtext__image--big {
  width: 100%;
  border-radius: $general-radius;
  margin-bottom: 1rem;
}
.richtext__image--small {
  border-radius: $general-radius;
  margin-bottom: 1rem;
}
.richtext__image {
  &.richtext__image--right {
    //border-top-left-radius: $general-radius;
    //border-bottom-left-radius: $general-radius;
    border-radius: $general-radius;
  }

  &.richtext__image--left {
    //border-top-right-radius: $general-radius;
    //border-bottom-right-radius: $general-radius;
    border-radius: $general-radius;
  }
}

.richtext-left {
  @include breakpoint(medium) {
    padding-right: 2rem;
  }
}

.richtext-right {
  @include breakpoint(medium) {
    padding-left: 2rem;
  }
}

.richtext__container {
  @include breakpoint(medium) {
    p {
      margin-bottom: 2rem;
    }
  }
}
