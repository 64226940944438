.teaser__column {
  .teaser-small__image {
    margin-bottom: 1rem;
  }

  @include breakpoint(small only) {
    //display: flex;
    border-top: 1px $general-border-style $medium-gray;
    padding-top: 2rem;
    margin-bottom: 2rem;
    overflow: hidden;
  }
}

.teaser__wrapper .cell:first-child .teaser__column {
  border-top: 0;
}
