$sticky-header-height: rem-calc(70);
$sticky-header-height-large: 6rem;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;
$stroke-width: 100%;

$nav-toggle-transition: 0.25s ease-in-out;

.header {
  padding-top: $sticky-header-height-large;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: $white;
  transition: 0.2s ease box-shadow, 0.2s ease background-color;
  box-shadow: 0 1px 3px -2px $black;
  .scrolled & {
    box-shadow: 0 1px 5px -2px $black;
  }
  max-height: $sticky-header-height-large;
}

// Navigation
// ==========

.navigation {
  position: relative;
  display: flex;
  align-items: center;

  padding-top: 1rem;
  padding-bottom: 1rem;

  text-transform: uppercase;
}

.navigation__social {
  @include breakpoint(large) {
    padding: 0.5rem 0 0 3rem;
  }
  @include breakpoint(medium only) {
    padding: 0.5rem 0 0 1.5rem;
  }
  @include breakpoint(small only) {
    padding: 1.5rem 0 5rem 0;
  }
  .navigation__social-link {
    @include breakpoint(large) {
      padding: 0 0 0 1rem;
    }
    @include breakpoint(medium only) {
      padding: 0 0 0 0.5rem;
    }
    @include breakpoint(small only) {
      padding: 0 0.75rem 0 0;
    }
    svg {
      height: 2rem;
      width: 2rem;
      fill: $moderate-blue;
      transition: fill 200ms ease-in-out, background-color 200ms ease-in-out;
      .social-border {
        fill: $moderate-blue;
      }
      &.social-background {
        fill: $moderate-blue;
      }
      &:hover {
        background-color: $moderate-blue;
        fill: $white;
      }
    }
  }
}

.navigation__list {
  left: -4px;
  margin: 0 0 0 auto;
  list-style: none;
  z-index: 0;
  position: absolute;
  top: 100%;
  width: 102%;
  padding: 0.5rem 2rem;
  transition: $nav-toggle-transition;
  transition-property: opacity transform;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  background: $white;
  @include breakpoint(small only) {
    height: calc(100vh - 5.6rem);
    overflow: auto;
  }

  .nav-is-open & {
    opacity: 1;
    visibility: visible;
  }

  @include breakpoint(medium up) {
    position: static;
    flex-direction: row;
    width: auto;
    padding: 0;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    box-shadow: none;
    left: 0;
    min-height: 0;
    display: flex;
  }
}

.navigation__breadcrumbs {
  @include breakpoint(medium) {
    display: none;
  }
  padding: 1rem 0;
  border-bottom: 1px $general-border-style $medium-gray;
  border-top: 1px $general-border-style $medium-gray;
  text-align: right;
  a {
    &:hover {
      border: 0;
    }
  }
}

.navigation__submenu {
  display: none;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px $general-border-style $medium-gray;
}

.navigation__submenu-item {
  padding: 0 1rem;
  margin-top: 0.5rem;
  @include breakpoint(medium) {
    display: none;
  }
  a {
    font-weight: normal;
    color: $black;
    font-family: $font-main;
    text-transform: none;
    &:hover {
      border-bottom: 0;
      color: lighten($moderate-blue, 10%);
    }
  }
  .active {
    color: $moderate-blue;
  }
}

.is--open {
  @include breakpoint(small only) {
    display: block;
  }
}

.rotate {
  transform: rotate(180deg);
}

/*
.navigation__item:hover .navigation__item-arrow {
  @include breakpoint(small only) {
    transform: rotate(180deg);
  }
}
*/

.navigation__item {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  list-style: none;
  .navigation__item--top {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .navigation__item-arrow {
    @include breakpoint(medium) {
      display: none;
    }
    fill: $dark-gray;
    height: 1rem;
    width: 1rem;
    transition: transform 200ms ease;
  }

  &:first-child {
    border-top: 1px $general-border-style $medium-gray;
    @include breakpoint(medium) {
      border-top: 0;
    }
  }
  border-bottom: 1px $general-border-style $medium-gray;
  @include breakpoint(medium) {
    padding: 0;
    border-bottom: 0;
    border-top: 0;
  }
  a {
    font-size: $body-font-size;
    @include breakpoint(medium) {
      font-size: $body-font-medium;
    }
    color: $black;
    transition: color 200ms ease;
    &:hover {
      border-bottom: 0;
      color: lighten($moderate-blue, 10%);
    }
  }
  .active {
    color: $moderate-blue;
  }

  @include breakpoint(medium up) {
    margin-left: calc(4 * (1vw + 1vh - 1vmin));
    width: auto;

    &:first-child {
      margin-left: 0;
    }
  }

  @include breakpoint(large) {
    margin-left: 4rem;
    width: auto;
  }
}

.navigation__link {
  // margin-left: 1rem;
  .navigation__link-img {
    height: 58px;
    width: 55px;
    background-image: url('./img/logos/ensemble.png');
    background-size: contain;
    background-repeat: no-repeat;

    .scrolled-home-video & {
      background-image: url('./img/logo-white.png');
    }

    .nav-is-open & {
      background-image: url('./img/logos/ensemble.png');
    }
  }
  &:hover {
    border-bottom: none;
  }
  @include breakpoint(small only) {
    margin-left: 1rem;
  }
}

// Navigation Toggle
// =================

.navigation__toggle {
  width: $icon-width;
  height: $icon-height;
  margin-left: auto;
  margin-right: 1rem;

  position: relative;
  cursor: pointer;
  &:hover {
    border-bottom: none;
  }
  .stroke {
    @extend %transition-ease;
    display: block;
    position: absolute;
    height: $stroke-height;
    width: 100%;
    .scrolled-home-video & {
      background-color: $white;
    }
    background-color: $black;

    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: $nav-toggle-transition;
    transition-property: transform, opacity, top, left;

    will-change: transform, opacity, top, left;

    .nav-is-open & {
      background-color: $black;
    }
    &:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: $icon-height / 2 - $stroke-height / 2;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: $icon-height - $stroke-height;
      transform-origin: left center;
    }

    .nav-is-open & {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: -2px;
        left: 0px;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 1.3125rem;
        left: 0px;
      }
    }
  }

  @include breakpoint(medium up) {
    display: none;
  }
}
