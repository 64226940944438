// stats

.icon__library {
  display: none;
}

.stats__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  hyphens: auto;
  @include breakpoint(small only) {
    margin-bottom: 0.5rem;
  }

  .icon {
    height: 100px;
    width: 100px;
    fill: $dark-gray;
  }
}
