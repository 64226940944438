.footer {
  .footer--big {
    color: $white;
    background-color: lighten($moderate-blue, 15);
    padding: 2.5rem 0;
    font-size: $body-font-size-small;
    .paragraph--small,
    h4 {
      color: $white;
    }

    @include breakpoint(small only) {
      .footer-left,
      .footer-navi {
        padding-bottom: 2rem;
        border-bottom: 1px $general-border-style $medium-gray;
        .footer-left__content,
        .footer-right__content {
          padding: 0 1rem;
        }
      }
      .footer-navi {
        padding-top: 2rem;
      }
    }
  }

  .footer-small {
    color: $white;
    background-color: $moderate-blue;
    padding: 1rem 0;
    .footer-small__container {
      display: flex;
      justify-content: space-between;
      @include breakpoint(small only) {
        flex-direction: column;
        text-align: center;
        .footer-small--first {
          padding-bottom: 1rem;
        }
      }
    }
  }
}

.footer-left {
  a {
    color: $black;
  }
}

// Footer Navigation

.footer-navi__list-item {
  font-size: $body-font-size;
  padding-bottom: 0.5rem;
  a {
    color: $white;
  }
}

.footer-navi__content {
  display: flex;

  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}
