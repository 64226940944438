.gallery-container {
  //  max-width: $global-width;
  //  margin-bottom: $content-element-margin-bottom;
  margin: 1rem 0 3rem 0;
  @include breakpoint(medium down) {
    overflow: hidden;
  }
}

.gallery-slide {
  position: relative;

  &--none {
    background-color: transparent;
  }
}

.gallery__slides {
  overflow-x: hidden;
  .label-box {
  }
}

.gallery__slider {
  display: flex;
}

.gallery__slide {
  color: white;
  position: relative;
  // background: $black;
  overflow: visible;
  display: flex;
  @include breakpoint(medium) {
    &:hover {
      .gallery__description {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

.gallery__go-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.gallery-image__wrapper {
  padding: 1rem 1rem;

  display: flex;
  align-items: center;
  width: 100%;

  overflow: hidden;
}

.gallery__image {
  display: block;
  width: 100%;
  max-height: 100%;
  border-radius: $general-radius;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.gallery__description {
  position: absolute;
  bottom: 2.75rem;
  align-self: flex-end;
  z-index: 2;
  background-color: $moderate-blue;
  //padding: 33px 87px 36px 40px;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;

  left: 0;
  min-height: 6rem;
  min-width: 15rem;
  padding: 2rem 6rem 2rem 2rem;
  overflow: hidden;
  transform: translateX(-1rem);
  @include breakpoint(medium only) {
    opacity: 1;
    transform: translateX(0);
    left: 4rem;
    bottom: 1rem;
    padding: 2rem 3rem 2rem 2rem;
    max-width: 50rem;
  }
  @include breakpoint(small only) {
    opacity: 1;
    transform: translateX(0);
    left: 0rem;
    bottom: 0rem;
    padding: 1rem 4rem 1rem 1rem;
    min-height: 4rem;
  }
  .gallery__description-title {
    margin: 0;
    font-weight: bold;
  }

  .gallery__description-caption {
    margin-bottom: 0rem;
  }
}

.gallery__go {
  position: relative;
  display: flex;
  font-size: 20px;
  line-height: 24px;
  color: $black;
  font-weight: 700;
  padding-bottom: 19px;
  margin-bottom: 50px;
  @include breakpoint(small only) {
    margin-bottom: 0px;
  }

  &:hover {
    color: $moderate-blue;
    text-decoration: none;
    &:after {
      top: calc(100% + 5px);
      border-color: $moderate-blue;
    }
  }
  &:after {
    content: '';
    width: 35px;
    height: 35px;
    border: solid $medium-gray;
    border-width: 0 10px 10px 0;
    position: absolute;

    top: 100%;
    left: 50%;
    margin-left: -13px;
    transform: rotate(45deg);
    transition: all 0.3s;
    @include breakpoint(small only) {
      width: 19px;
      height: 19px;
      border-width: 0 5px 5px 0;
    }
  }
  &[data-go='-1'] {
    position: absolute;
    display: block;
    top: 40%;
    left: 1rem;
    @include breakpoint(large) {
      left: 0;
    }
    transform: rotate(90deg);
    @include breakpoint(medium only) {
      left: 5rem;
      top: 13rem;
    }
    @include breakpoint(small only) {
      display: none;
    }
  }
  &[data-go='1'] {
    position: absolute;
    top: 40%;
    display: block;

    right: 1rem;
    @include breakpoint(large) {
      right: 0;
    }
    transform: rotate(-90deg);
    @include breakpoint(small only) {
      display: none;
    }
  }

  @include breakpoint(medium only) {
    height: 100%;
  }
}

.gallery__thumbs {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
  [data-thumb] {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    background-color: $medium-gray;
    border-radius: 50%;
    margin: 3px 0.5rem;
  }

  [data-current] {
    background-color: $moderate-blue;
  }
}

// Persons

.person__content {
  h3 {
    margin-top: 0.25rem;
    text-align: center;
  }

  p {
    text-align: center;
  }
}
