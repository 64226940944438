/* Styles for the home page*/

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home {
  .header {
    padding-top: 0;
  }

  .sticky-header {
    background-color: $white;
    opacity: 0;
    animation: fade-in 1s ease-out alternate forwards;
  }

  &.scrolled-home-video {
    .sticky-header {
      background-color: transparent;
      box-shadow: none;
    }
    @include breakpoint(medium) {
      .navigation__item a {
        color: $malibu;

        &:hover {
          color: $white;
        }
      }

      .navigation__social-link svg {
        fill: $malibu;

        .social-border {
          fill: $malibu;
        }

        &:hover {
          background-color: $malibu;
          fill: $white;
        }
      }
    }
  }

  &.nav-is-open {
    .sticky-header {
      background-color: $white;
    }
  }
}

.home-video {
  overflow: hidden;
  position: relative;
  transition: opacity 0.2s ease-in-out;
  color: $white;

  p {
    font-size: 1rem;
    margin-bottom: 2rem;

    animation: fade-in 1s ease-out alternate forwards;
    animation-delay: 0.6s;
    opacity: 0;

    @include breakpoint(medium up) {
      font-size: 1.125rem;
    }
  }

  &::after {
    display: block;
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: transparentize($secondary, 0.2);
  }
  &.show-video {
    &::after {
      background-color: transparentize($secondary, 1);
    }
  }
}

.home-video__title {
  color: $white;
  font-size: 2rem;
  animation: fade-in 1s ease-out alternate forwards;
  animation-delay: 0.4s;
  opacity: 0;

  @include breakpoint(medium up) {
    font-size: 3rem;
  }
}

.home-video__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (min-aspect-ratio: 16/9) {
    height: 300%;
    top: -100%;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 150%;
    left: -25%;
  }
}

.home-video__visibility-toggle {
  display: block;
  width: 1.75rem;
  padding: 0;

  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 20;
  transition: opacity 0.2s ease-in-out;

  //svg {
  //  display: block;
  //  padding: 0;
  //  fill: $moderate-blue;
  //
  //  transition: fill 0.2s ease-in-out;
  //}

  //&::before {
  //  content: '';
  //  display: block;
  //  width: 100%;
  //  height: 4px;
  //  background-color: $white;
  //
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //
  //  transform: translate(-50%, -50%) rotate(135deg);
  //  transition: opacity 0.2s ease-in-out;
  //
  //  opacity: 0;
  //}

  svg {
    fill: $white;
  }

  &.home-video__visibility-toggle--play {
    opacity: 1;

    .show-video & {
      opacity: 0;
    }
  }
  &.home-video__visibility-toggle--pause {
    opacity: 0;
    .show-video & {
      opacity: 1;
    }
  }
}

[data-home-video-play] {
  .show-video & {
    cursor: auto;
  }
}

.home-video__content {
  position: relative;
  z-index: 10;
  padding-top: 15vh;
  padding-bottom: 12vh;

  transition: 0.2s ease-in-out opacity;

  @include breakpoint(medium) {
    padding-top: 24vh;
    padding-bottom: 24vh;
  }

  .show-video & {
    opacity: 0;
  }
}
