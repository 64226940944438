// Partners
.partners__list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.partners__item {
  @include breakpoint(medium) {
    flex: 1 1 50%;
    max-width: 50%;
    min-height: 8.4375rem;
  }
  flex: 1 1 100%;
  max-width: 100%;
  min-height: 6rem;
  list-style: none;
}

.partners__content {
  padding-left: 3rem;
  padding-bottom: 1rem;

  hyphens: auto;

  p {
    padding-top: 0.15rem;
    margin-bottom: 0;
  }
}

.partners__logo {
  img {
    max-height: 6rem;
  }
}

.partners__logo-gc {
  margin-top: -1rem;
}
