$black: #202020;
$white: #ffffff;

// main colors
/* Blue */
$moderate-blue: #1a7cc4;
$malibu: #b2ddfe;

/* Gray */
$light-gray: #f7f7f7;
$medium-gray: #c1c1c1;
$dark-gray: #646464;

$ripe-lemon: #f8da22;

$primary: $black;
$secondary: $moderate-blue;

$headings-color: $black;

// Box shadows
$general-box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.17);
$general-box-shadow-hover: 2px 3px 5px 0 rgba(0, 0, 0, 0.33);

// Radius

$general-radius: 0.8rem;
$general-radius-small: 0.2rem;

// grid
$grid-gap: 20px;
