.button-standard {
  @extend %transition-ease;
  transition-property: opacity;
  display: inline-block;
  padding: 0.5rem 2rem;
  font-family: $font-main;
  font-weight: normal;
  color: $white;
  margin-bottom: $general-block-margin;
  background-color: $moderate-blue;

  &:hover,
  &:focus {
    opacity: 0.7;
    border-bottom: none;
  }
}

.button-wrapper {
  margin-bottom: $general-block-margin;

  @include breakpoint(medium up) {
    display: flex;
    align-items: center;

    .button-standard {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
}

.button-wrapper__label {
  display: block;
  font-size: $body-font-size-small;

  @include breakpoint(medium up) {
    font-size: $body-font-size;
  }
}

.button {
  display: inline-block;
  color: $white;
  box-shadow: $general-box-shadow;
  border-radius: rem-calc(9);
  text-transform: uppercase;
  font-family: $font-bold;
  font-size: rem-calc(12);
  padding: rem-calc(17) 1.75rem rem-calc(19);
  line-height: 1;
  transition: 0.15s ease-in-out;
  transition-property: background-color, box-shadow;
  animation: fade-in 1s ease-out alternate forwards;
  animation-delay: 0.6s;
  opacity: 0;
  background-color: $moderate-blue;

  &:hover {
    background-color: lighten($moderate-blue, 10%);

    box-shadow: $general-box-shadow-hover;
  }

  &.button--yellow {
    color: $black;
    background-color: $ripe-lemon;
    &:hover {
      background-color: lighten($ripe-lemon, 10%);
    }
  }

  &.button--center {
    margin: 0 auto;
    padding: rem-calc(17) 2rem rem-calc(19);
  }

  &.button--margin {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include breakpoint(medium) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}
