.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: 0;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable {
  tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.timeline__item {
  transition: all 0.3s ease-in-out;
  width: 20rem;
  border-radius: $general-radius;
  min-height: 17rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 1rem 0.5rem 2rem 0.5rem;

  @include breakpoint(medium) {
    margin: 8rem 3rem 4rem 3rem;
    &.is-selected {
      transform: translateY(-20%);
    }
    &.timeline__item--up {
      transform: translateY(-20%);
    }
  }

  &.is-selected {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

.timeline__item--odd {
  &.timeline__item--up {
    @include breakpoint(medium) {
      transform: translateY(-20%);
    }
  }
}

.timeline__item--second {
}

.timeline__date {
  background: $moderate-blue;
  color: $white;
  padding: 1rem;
  width: 100%;
  display: block;
  font-weight: bold;
  border-top-left-radius: $general-radius;
  border-top-right-radius: $general-radius;
  z-index: 10000;
  position: relative;

  .timeline--past & {
    background: lightgrey;
  }
}

.timeline__box {
  padding: 1rem;
}

.timeline__line {
  position: absolute;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease-in-out;
  z-index: -100;
  @include breakpoint(small only) {
    display: none;
  }

  &--right {
    top: -20px;
    right: -250px;
    transform-origin: 0 100%;
    height: 55px;
    width: 218px;
    background-image: url('./img/line.svg');
    background-repeat: no-repeat;
    z-index: -100;

    .timeline--past & {
      background-image: url('./img/line-past.svg');
    }
    .timeline__item--up & {
      transform: translate(-50%, -50%) rotate(34deg);
    }

    .timeline__item--last & {
      display: none;
    }
  }
  &--left {
    top: -20px;
    left: -35px;
    transform-origin: 100% 100%;
    height: 55px;
    width: 218px;
    background-image: url('./img/line-left.svg');
    background-repeat: no-repeat;
    z-index: -100;

    .timeline--past & {
      background-image: url('./img/line-left-past.svg');
    }

    .timeline__item--up & {
      transform: translate(-50%, -50%) rotate(-34deg);
    }

    .timeline__item--first & {
      display: none;
    }
  }

  .timeline__item--even & {
    display: none;
  }
}

.timeline__title,
.timeline__text {
  .timeline--past & {
    color: $dark-gray;
  }
}
