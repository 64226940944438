.form--general {
  display: flex;
  flex-wrap: wrap;

  &:after {
    content: '';
    flex: auto;
  }

  .widget--textinput,
  .widget--emailinput {
    display: flex;
    flex-flow: column;
    flex: 20em;
    margin: 0.1em;
    padding: 0.25em 0.5em 0.25em 0;
  }
  .widget--emailinput {
    flex: 100%;
  }
  label {
    display: inline-block;
    margin-bottom: 0.25rem;

    &.required::after {
      content: ' *';
    }
  }

  label[for='id_captcha'],
  .widget--recaptcha {
    display: none;
  }

  .g-recaptcha {
    margin: 0.1em;
    padding: 0.25em 0.5em 0.25em 0;
    width: 100%;
  }

  button[type='submit'] {
    @extend .button-standard;
    // flex: 10em;
    height: 4rem;
    margin: 0.5em 0.1em;
    padding: 0.25em 0.5em 0.25em 0;
  }

  input,
  textarea {
    display: block;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border: 1px solid $medium-gray;
    width: 100%;

    @include breakpoint(medium up) {
      padding: 0.75rem 1rem;
    }
  }

  select {
    display: block;
    margin-bottom: 1rem;
  }

  .button {
    margin-top: 1rem;
  }
}

.error {
  input,
  textarea {
    margin-bottom: 0;
  }
}

.form__errors {
  margin-bottom: $general-block-margin;
  font-weight: bold;
}

.errorlist {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  list-style: none;
  color: red;
  font-size: $body-font-size-small;
  width: 100%;
  li {
    &::before {
      content: '* ';
    }
  }
}

.widget--textinput,
.widget--emailinput,
.widget--textarea {
  width: 100%;

  @include breakpoint(medium up) {
    //width: 75%;
  }
}

.newsletter__snippet {
  margin-bottom: $general-block-margin;
}

.form--submit-text {
  margin-top: 3rem;
}

.form__newsletter {
  display: flex;
  flex-direction: column;
}

.newsletter__container {
  display: flex;
  margin: 0 auto;
  width: 90%;

  @include breakpoint(large) {
    width: 100%;
  }
}

.newsletter__header {
  text-align: center;
  margin-bottom: 2rem;
}

.newsletter__input {
  flex: 1 1 70%;
  input {
    border: none;
    background-color: $light-gray;
    box-shadow: $general-box-shadow;
    text-align: center;
    border-top-left-radius: rem-calc(9);
    border-bottom-left-radius: rem-calc(9);
    height: 3rem;

    &:focus {
      outline: none;
    }
  }
}
.newsletter__submit {
  flex: 1 1 30%;
  .newsletter__button {
    display: block;
    color: $white;
    border-top-right-radius: rem-calc(9);
    border-bottom-right-radius: rem-calc(9);
    box-shadow: $general-box-shadow;
    height: 3.1rem;

    text-transform: uppercase;
    font-family: $font-bold;
    font-size: rem-calc(12);
    padding: rem-calc(17) 1.75rem rem-calc(19);
    line-height: 1;
    transition: 0.15s ease-in-out;
    transition-property: background-color, box-shadow;
    background-color: $moderate-blue;
  }
}
