.building-map {
  @include breakpoint(medium) {
    width: 80%;
  }
  width: 100%;
}

.square {
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
}

.legend-container {
  &:first-of-type {
    padding: 0.8rem 0 0 0;
  }
  display: flex;

  .square {
    margin-right: 1.3rem;
  }

  .legend-caption {
    font-family: $font-italic;
    font-size: 14px;
  }

  .arrows {
    margin-right: 0.8rem;
    height: 1.5rem;
    width: 2rem;
  }
}
