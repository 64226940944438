.articles__snippet {
  padding: $general-padding 0;
  width: 100%;
  background: $moderate-blue;
  background: linear-gradient(
    0deg,
    rgba(29, 27, 55, 1) 0%,
    rgba(26, 124, 196, 1) 81%,
    rgba(26, 124, 196, 1) 100%
  );
}

.articles__image {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: $general-radius;
  transition: 0.6s;

  width: 100%;

  &.articles__image--large {
    box-shadow: none;
  }

  &.articles__image--news {
    transition: none;
  }
}

.articles__link {
  @include breakpoint(medium) {
    &:hover {
      .articles__image {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }
  }
}

.articles__item {
  @include breakpoint(small only) {
    margin-bottom: 2rem;
    //&.articles__item--first {
    //  border-bottom: 2px solid transparentize($medium-gray, 0.8);
    //  padding-bottom: 1rem;
    //}
  }

  &.articles__item--small {
    //border-bottom: 2px solid transparentize($medium-gray, 0.8);
    //&.articles__item--last {
    //  border-bottom: none;
    //}
    overflow: hidden;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    @include breakpoint(large) {
      display: flex;
      padding-bottom: 0;

      .articles__image-container {
        flex: 1 0 33%;
        margin-bottom: 2rem;
        margin-right: 1rem;
      }
    }
  }
}

.articles__image-container {
  margin-bottom: 0.5rem;
}

.article__date {
  @extend .paragraph--small;
  color: $light-gray;
  margin-bottom: 0.5rem;
  display: block;

  @include breakpoint(medium) {
    margin-bottom: 1rem;
  }
}

.articles__content {
  color: $white;
  display: flex;
  flex-direction: column;
}

.articles__excerpt {
  font-family: $font-main;
  color: $black;

  &.articles__excerpt--white {
    color: $white;
  }
}

// Article Block & Grid

.articles {
  display: flex;
  width: 100%;
  margin-bottom: $general-block-margin;
  justify-content: space-around;
  flex-wrap: wrap;

  .articles__article {
    //flex: 1 1 25%;
  }
  &.article-grid--others {
    margin-bottom: $general-block-margin;
    margin-top: $general-block-margin;
  }
}

// For Modern Browser with CSS Grid Support
@supports (grid-area: auto) {
  .articles {
    display: grid;
    grid-gap: $grid-gap;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: $grid-gap;
  }
}

.articles > .articles__article {
  flex: 1 1 100%;
  //transition: $general-transitions;
  //background-color: $article-block-color;
  margin-bottom: 1rem;
  &:hover {
    //box-shadow: $general-box-shadow;
  }

  @include breakpoint(medium) {
    flex: 0 1 32%;
  }
}

.articles__article {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;

  //border-bottom-left-radius: $general-radius;
  //border-bottom-right-radius: $general-radius;
  //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  .articles__image-wrapper {
    position: relative;
    padding-bottom: 61%;
    margin-bottom: 1rem;

    .articles__image {
      position: absolute;
      box-shadow: none;
      //border-bottom-left-radius: 0;
      //border-bottom-right-radius: 0;
    }
  }

  .teaser__content {
    hyphens: auto;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    position: relative;
    z-index: 1000;
    //background-color: $article-block-color;
    height: auto;

    //.teaser__item-header {
    //  span {
    //    font: $caption-font;
    //    color: $gray;
    //  }
    //}

    .teaser__title {
      margin-bottom: 0.75rem;
    }
  }
}

.articles__article-content {
  @include breakpoint(medium) {
    border-bottom: 2px solid $medium-gray;
  }

  .articles__article--no-border & {
    border: none;
  }
}

.articles__header {
  display: flex;
}

.articles__category {
  @extend .article__date;
  background: $moderate-blue;
  padding: 0.35rem 0.75rem;
  margin-right: 1.5rem;
  border-radius: $general-radius-small;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
  font-family: $font-bold;

  &.articles__category--white {
    color: $moderate-blue;
    background: $white;
  }
}

.articles__date {
  @extend .article__date;
  color: $dark-gray;
  align-self: center;

  &.articles__date--white {
    color: $white;
  }
}

.news__button {
  text-align: center;
}

// Detail Page

.articles-detail__image-wrapper {
  margin-top: $general-block-margin;
  margin-bottom: $general-block-margin;
}
