.logos--plain {
  padding-top: $general-block-margin;
}

.logos__title {
  display: block;
  margin-bottom: $general-block-margin;
}

.logos__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: $general-block-margin;

  .logo {
    margin: 0 0.65rem 1.25rem;
    height: 4rem;
    width: 5rem;
  }

  .logo--aqua-viva,
  .logo--fkz {
    width: 6.5rem;
  }

  @include breakpoint(medium up) {
    .logo {
      height: 5rem;
      width: 6rem;
    }

    .logo--aqua-viva,
    .logo--fkz {
      width: 8rem;
    }
  }

  @include breakpoint(large up) {
    flex-wrap: nowrap;
    justify-content: space-between;

    .logo {
      height: 4.5rem;
      width: auto;
      margin: 0;
    }

    .logo--aqua-viva {
      height: 3rem;
      width: auto;
    }

    .logo--fkz {
      height: 3.8rem;
      width: auto;
    }
  }
}
