// Sidenav
.sidenav {
  &:last-of-type {
    border-bottom: 1px $general-border-style $medium-gray;
  }

  > a {
    font-family: $font-main;
  }
}

.sidenav__item {
  border-top: 1px $general-border-style $medium-gray;
  padding: 1rem 0.5rem;
  line-height: 1;
  transition: background-color 200ms ease;
  &:hover {
    :last-of-type {
      border: 0;
    }
    background-color: $moderate-blue;
    color: $white;
    border-top: 1px $general-border-style transparent;
  }
}

.sidenav--active {
  position: relative;
  background-color: $moderate-blue;
  border-top: 1px $general-border-style transparent;

  z-index: 100;
  margin-bottom: -1px;
  p {
    color: $white;
  }
}
