.faq-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.js-foldout-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.faq-list {
  margin-bottom: 4rem;
}

.faq-margin {
  margin: 0 0.38rem 3rem 0.38rem;

  @include breakpoint(small only) {
    margin: 0 1.5rem 2rem 1.5rem;
  }
}

.faq-column {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.faq {
  border: 0px solid $medium-gray;
  border-top-width: 1px;

  width: 100%;
  display: table-row;
  border-bottom-width: 1px;
}
.arrow--vertical {
  color: $moderate-blue;
}
.faq__header {
  cursor: pointer;

  padding: 0.4rem 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    padding: 1rem;
    background: url('img/arrow-vertical-blue.svg') center / 50% no-repeat;
    transform: rotate(-90deg);
    transition: transform 200ms ease-in-out;

    .toggled & {
      transform: rotate(-180deg);
    }
  }
}

.faq__contents {
  border: 0px solid $medium-gray;
  border-top-width: 1px;
  padding: 1rem 0 0.1rem 0;
  .snippet--small {
    padding: 0;
  }
}

.faq-title {
  margin: 1rem 0;
}
