// Breadcrumbs & Subheader

.subheader {
  display: flex;
  justify-content: space-between;
}

.back-button {
  &:hover {
    border: 0;
  }
  @include breakpoint(small only) {
    svg {
      max-width: 8rem;
      margin-right: 2rem;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;

  span {
    vertical-align: middle;
    font-size: $body-font-size-tiny;
  }

  a {
    &:hover {
      border: 0;
    }
  }
}

.breadcrumb--active {
  color: $medium-gray;
}
