%transition-ease {
  transition: 0.2s ease;
}

// Sticky Footer
// =============

body {
  display: flex;
  flex-direction: column;
  // I would rather use min-height, but IE11 crashes the party
  // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  min-height: 100vh;
  &.nav-is-open {
    position: fixed;
  }
  -webkit-overflow-scrolling: touch;
}

.site-content {
  display: flex;
  flex-direction: column;
  // I would rather use min-height, but IE11 crashes the party
  // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  min-height: 100vh;

  position: relative;
  height: 100%;
}

.main-content {
  height: 100%;
  flex: 1 0 auto;

  .page--standard & {
    padding: 1.5rem 0;

    @include breakpoint(medium up) {
      padding: 3rem 0;
    }

    @include breakpoint(large up) {
      padding: 5rem 0;
    }
  }
}

// General elements / behavior styling
// ===================================

a {
  color: $moderate-blue;
  font-family: $font-bold;
}

p,
form,
table {
  margin-bottom: $general-block-margin;
}

ul,
ol {
  margin-bottom: $general-block-margin;
  padding-left: 1rem;
}

address,
time {
  @extend .paragraph--small;
  font-style: normal;
}

// Image Content
// =============

.image {
  margin-bottom: $general-block-margin;
}

.image__caption {
  font-weight: bold;
  margin-bottom: 0;
}

.image__copyright {
  font-style: italic;
  font-size: $body-font-size-small;

  @include breakpoint(medium up) {
    font-size: $body-font-size;
  }
}

// Navigation anchor
// =================

.anchor {
  position: relative;
  top: -1.5rem;
}

// Flex Video
// ==========

.flex-video {
  margin-bottom: $general-block-margin;
}

// Alternating Background Colors
// =============================

// snipptes

.snippet {
  padding: 0 1rem;
  margin: 1.5rem 0;
  @include breakpoint(medium) {
    margin: 4rem 0;
    padding: 0;
  }
}

.snippet--small {
  padding: 0 1rem;
  margin: 0.75rem 0;
  @include breakpoint(medium) {
    margin: 2rem 0;
    padding: 0;
  }
}

.margin--bottom {
  margin-bottom: 1rem;
  @include breakpoint(medium) {
    margin-bottom: $general-block-margin * 2;
    padding: 0;
  }
}

.margin--top-less {
  margin-top: 0;
}

.margin--bottom-large {
  margin-bottom: 2rem;
  @include breakpoint(medium) {
    margin-bottom: $general-block-margin * 3;
    padding: 0;
  }
}

.snippet__header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
}
.snippet__header--white {
  color: $white;
  margin-bottom: 0.8rem;

  h2 {
    color: $white;
  }
}
.test {
  background: red;
}

hr {
  border: 0;
  border-bottom: 1px $general-border-style $medium-gray;
  margin: 0 1rem;
  @include breakpoint(medium) {
    margin: 0;
  }
}

// Subpage

.subpage__richtext {
  padding-top: 1rem;
  margin-left: 0;
  @include breakpoint(medium) {
    padding-left: 3rem;
  }
}

.subpages-arrow {
  margin-top: auto;
}

/*
.responsive-embed {
  margin-left: 1.3rem;
  margin-right: 1.3rem;
  @include breakpoint(medium) {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
*/
