// Teasers

.teaser-small {
  &:first-of-type {
    @include breakpoint(small only) {
      border-top: 1px $general-border-style $medium-gray;
      padding-top: 1rem;
    }
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
  border-bottom: 1px $general-border-style $medium-gray;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.teaser-small__container {
  display: flex;
  flex-direction: row;
  .paragraph--medium {
    padding-right: 0.5rem;
    hyphens: auto;
    flex: 1 0 50%;
  }
  .teaser-small__image {
    flex: 1 0 50%;
  }
}

.teaser-big {
  padding-right: 2rem;
  height: 100%;
  @include breakpoint(small only) {
    border-right: none;
    padding-right: 0;
    height: auto;
  }
  border-right: 1px $general-border-style $medium-gray;

  h1 {
    padding-right: 1rem;
  }
}

.teaser-big__container {
  // display: flex;
  @include breakpoint(small only) {
    display: block;
  }
  flex-direction: column;
  .paragraph--medium {
    hyphens: auto;
    padding-bottom: 1rem;
    flex: 1 0 50%;
  }
  .teaser-big__image {
    @include breakpoint(small only) {
      padding-bottom: 1rem;
    }
  }
}

.teaser-button__container {
  margin-bottom: 0.5rem;
  transform: translate(0%, -25%);
}

.teaser-button {
  font-size: 8px;
  letter-spacing: 1px;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  border: 1px solid $moderate-blue;
  transition: background-color 200ms ease, color 200ms ease;
  &:hover {
    background-color: $moderate-blue;
    color: $white;
  }
}
