/* http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
*,
*:after,
*:before {
  box-sizing: inherit;
}
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: 16px/1 sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}
main {
  display: block;
}
h1,
h2,
h3,
h4 {
  font-size: inherit;
}
strong {
  font-weight: bold;
}
a,
button {
  color: inherit;
}
a {
  text-decoration: none;
}
button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border: 0;
}
:focus {
  // do not kill accessibility!
  // outline: 0;
}
img {
  max-width: 100%;
  height: auto;
  border: 0;
}
//figure {
//  -webkit-margin-before: 0;
//  -webkit-margin-after: 0;
//  -webkit-margin-start: 0;
//  -webkit-margin-end: 0;
//}
