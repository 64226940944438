// Featured Teasers

.featured-subpage__container {
  position: relative;
}

.card__link {
  font-family: $font-main;
}

.card__container {
  position: relative;
  min-height: 250px;
  margin: 0 auto;
  @include breakpoint(medium) {
    min-height: 300px;
  }
}

.card {
  position: relative;
  transition: 0.6s;
}

.card__content {
  background: $moderate-blue;
  color: #fff;
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  text-align: left;
  cursor: pointer;
  transition: 0.6s;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 100%;
  border-radius: 0.8rem;
  @include breakpoint(medium) {
    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);

      opacity: 1;
      .card__caption:before {
        opacity: 1;
      }

      h3 {
        color: #fff;
        transform: translateY(-58%);
        @include breakpoint(large) {
          transform: translateY(-48%);
        }
      }

      p {
        opacity: 1;
        transform: translateY(30%);

        @include breakpoint(large) {
          transform: translateY(30%);
        }
      }
      .subpages-arrow {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  .card__image {
    position: relative;
    display: block;
    min-height: 100%;
    opacity: 0.7;
    height: 100%;
    width: 100%;

    &.card__image--blocker {
      @include breakpoint(medium) {
        min-height: 328px;
      }

      @include breakpoint(large) {
        min-height: 286px;
      }
    }
  }

  .card__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparentize($moderate-blue, 0.2);
      content: '';
      opacity: 0;
      transition: opacity 0.35s, transform 0.35s;
    }
  }

  .card__top-title {
    font-size: 9px;
    letter-spacing: 1px;
    font-family: $font-bold;
    text-transform: uppercase;
    //border: 1px solid #377ac8;
    transition: background-color 200ms ease, color 200ms ease;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem 0 0 1rem;
    color: $white;
  }

  h3 {
    word-spacing: -0.15em;
    width: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    align-items: flex-end;
    display: flex;
    color: $white;
    transition: transform 0.35s, color 0.35s;
    padding-left: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    transform-origin: 0 100%;
    @include breakpoint(medium) {
      width: 13rem;
    }
    @include breakpoint(small only) {
      font-size: 1rem;
      hyphens: auto;
    }
  }

  p {
    letter-spacing: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3.5rem;
    transform: translate3d(0, 180px, 0);

    transition: opacity 0.35s, transform 0.35s;
    margin: 0;
    height: 100%;
    align-items: flex-start;
    display: flex;
    @include breakpoint(large) {
      font-size: 75%;
      transform: translate3d(0, 100px, 0);
    }
  }

  .subpages-arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    padding: 0 0.8rem 0.5rem 0;
    width: 100%;
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.35s, transform 0.35s;
    margin: 0;
    img {
      float: right;
    }
  }
}

.card__button {
  margin-top: $general-block-margin;
}
